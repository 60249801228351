import React from 'react'
import styled from 'styled-components'
import { useData } from '../stores'
import { theme, raise } from '../theme'

export const BrandColorLine: React.FunctionComponent = () => {
  const { electionData } = useData(({ dataStore }) => ({
    electionData: dataStore.electionData,
  }))
  return (
    <BrandColorElement mainColor={electionData.election.brandColor ?
      electionData.election.brandColor.mainColor :
      theme.color.white}
    />
  )
}

const BrandColorElement = styled.div<({ mainColor: string })>`
  width: 100%;
  height: 5px;
  background-color: ${props => props.mainColor};
  ${raise(1)};
`
