/* eslint-disable */
import axios, { AxiosError } from 'axios'

export const {PUBLIC_API_URL} = process.env

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const rootElement = document.getElementById('voting-compass-app')
let inAdminUi = false
if (rootElement && rootElement.getAttribute('in-admin-ui')) {
  inAdminUi = true
}

const createRequest = async (path: string, options: any): Promise<any> => {
  if (inAdminUi) {
    options.headers.Authorization = `Bearer ${window['jwtToken']}`
  }
  return axios(`${PUBLIC_API_URL}/${path}`, { ...options })
    .then((res: { data: any }) => res.data)
    .catch((err: AxiosError) => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            if (options.headers && options.headers.Authorization) {
              console.log('Istuntosi on vanhentunut. Kirjaudu uudestaan')
            }
            break
          case 403:
            console.log('Oikeutesi eivät riitä kyseisen toiminnon suorittamiseen')
            break
          case 502:
          case 503:
          case 504:
            console.log('Palvelu on tilapäisesti tavoittamattomissa. Ole hyvä ja kokeile hetken päästä uudestaan')
            break
          case 500:
            console.log('Tapahtui odottamaton virhe. Ole hyvä ja ota yhteyttä ylläpitoon')
            break
          default:
            break
        }
        throw new Error(JSON.stringify(err.response))
      }
    })
}

export const get = <T>(path: string, headers = defaultHeaders): Promise<T> =>
  createRequest(path, { headers, method: 'GET' })

export const post = <T>(path: string, data: any, headers = defaultHeaders): Promise<T> =>
  createRequest(path, { headers, data, method: 'POST' })

export const put = <T>(path: string, data: any, headers = defaultHeaders): Promise<T> =>
  createRequest(path, { headers, data, method: 'PUT' })

export const del = <T>(path: string, headers = defaultHeaders): Promise<T> =>
  createRequest(path, { headers, method: 'DELETE' })
