import React from 'react'
import { useObserver } from 'mobx-react-lite'
import { ResultStore } from './ResultStore'
import { DataStore } from './DataStore'
import { SessionStore } from './SessionStore'

export interface IStores {
  dataStore: DataStore
  resultStore: ResultStore
  sessionStore: SessionStore
}
export const StoreContext = React.createContext<IStores | null>(null)

export const useData = <Selection>(dataSelector: (stores: IStores) => Selection) => {
  const stores = React.useContext(StoreContext)
  if (!stores) {
    throw new Error('Stores must be used within a StoreProvider.')
  }
  return useObserver(() => dataSelector(stores))
}
