export type Intent = 'primary' | 'warning' | 'error' | 'success' | 'default' | 'neutral' | 'secondary'

export type Size = 'xs' | 's' | 'm' | 'l'

export const theme = {
  color: {
    brandColor: {
      mainColor: '#FFAA00',
      secondaryColor: '#FFE59E',
      textColor: '#ffffff'
    },
    white: '#ffffff',
    orange: {
      lightest: '#FFE59E',
      light: '#FFC94D',
      default: '#FFAA00',
      dark: '#DB8B00',
    },
    grey: {
      lightest: '#f6f9fc',
      light: '#ced4da',
      default: '#6c757d',
      dark: '#525f7f',
      darkest: '#32325d'
    },
    error: {
      default: '#ff3d71'
    },
    warning: {
      default: '#ffaa00'
    },
    success: {
      default: '#00b178',
      dark: '#00855B'
    },
    info: {
      default: '#0095ff'
    },
    black: '#212529',
    blue: {
      light: '#A7E5F9'
    }
  },
  breakPoints: {
    // Extra small
    xs: '425px',
    // Small
    sm: '540px',
    // Medium
    md: '720px',
    // Large
    lg: '960px',
    // Extra large
    xl: '1140px'
  },
  fontSize: {
    xs: '11px',
    small: '14px',
    medium: '16px',
    mediumLarge: '18px',
    large: '24px',
    xlarge: '40px',
    xxlarge: '64px',
  },
  font: {
    text: "'Open Sans', sans-serif",
    header: "'Open Sans', sans-serif",
    number: "'Lato', sans-serif"
  },
}

export const raise = (level: number): string => {
  switch (level) {
    case 1:
      return 'box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1)'
    case 2:
      return 'box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.125)'
    case 3:
      return 'box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.150)'
    case 4:
      return 'box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.175)'
    case 5:
      return 'box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.2)'
    default:
      return ''
  }
}

export const getFontSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return 12
    case 's':
      return 14
    case 'm':
      return 14
    case 'l':
      return 16
    default:
      return 14
  }
}

export const getSize = (base: number, size: Size) => {
  switch (size) {
    case 's':
      return `${base / 4}px ${base / 2}px`
    case 'm':
      return `${base}px ${base * 2}px`
    case 'l':
      return `${base * 2}px ${base * 4}px`
    default:
      return `${base}px ${base * 2}px`
  }
}

export const getBackgroundColor = (type: Intent) => {
  switch (type) {
    case 'error':
      return theme.color.error.default
    case 'primary':
      return theme.color.brandColor.mainColor
    case 'success':
      return theme.color.success.default
    case 'warning':
      return theme.color.warning.default
    case 'neutral':
      return '#fff'
    case 'secondary':
      return '#e3e3e3'
    default:
      return theme.color.brandColor.mainColor
  }
}

export const getColor = (type: Intent) => {
  switch (type) {
    case 'primary':
      return theme.color.brandColor.textColor
    case 'secondary':
      return '#4a5055e6'
    default:
      return '#fff'
  }
}
