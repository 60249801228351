import React from 'react'
import styled from 'styled-components'
import { readableColor, lighten } from 'polished'
import { theme } from '../theme'

interface IProps {
  text: string
  onClick: (value: string) => void
  color?: string
  isSelected: boolean
  hoverText?: string
  'data-cy'?: string
}

export const Pill: React.FC<IProps> = ({ text, onClick, color, isSelected, hoverText, ...props }) => {
  const pillClick = () => {
    onClick(text)
  }

  return (
    <PillWrapper data-cy={props['data-cy']} title={hoverText} onClick={() => pillClick()} isSelected={isSelected} color={color}>
      <p>{text}</p>
    </PillWrapper>
  )
}

const PillWrapper = styled.div<({ isSelected: boolean, color?: string })>`
  cursor: pointer;
  width: max-content;
  height: 30px;
  background-color: ${({ color, isSelected }) => lighten( isSelected ? 0 : 0.2, color || theme.color.grey.default)};
  border-radius: 15px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 2.5px;
  max-width: 100%;

  p {
    user-select: none;
    margin: 0;
    color: ${({ color, isSelected }) => readableColor(lighten( isSelected ? 0 : 0.2, color || theme.color.grey.default), theme.color.grey.dark, theme.color.grey.lightest)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
