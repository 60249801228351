import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../theme'

import { GroupTag } from '../atoms/GroupTag.atom'
import { useData } from '../stores'
import { ICandidate, ICategory } from '../types/data.types'
import { LinkCard } from '../elements/LinkCard'
import { ResultDial } from '../elements/ResultDial'
import { IResult } from '../types/result.types'
import { RoundImage } from '../elements/Image'
import { toLanguagePath } from '../utils/I18nHelper'
import { device } from '../theme/deviceSize'

interface IProps {
  className?: string
  candidate: ICandidate
  categories: ICategory[]
  score: IResult
}

const ResultCardElement: React.FC<IProps> = ({ candidate, className, score, categories }) => {
  const showCategories = categories?.length > 0 && score.categoryScores && Object.keys(score.categoryScores).length > 0

  const { electionData } = useData(({ dataStore }) => ({
    electionData: dataStore.electionData
  }))

  const candidateGroups =
    (candidate && electionData.groups.length > 0 && candidate.groupId) ? electionData.groups.filter(g => g.id === candidate.groupId) : []
  const groupName = candidateGroups.length > 0 ? candidateGroups[0].abbreviation : ''
  const groupColor = candidateGroups.length > 0 ? candidateGroups[0].color : theme.color.blue.light

  const { i18n, t } = useTranslation()

  return (
    candidate &&
    <LinkCard className={className} to={toLanguagePath(`/ehdokkaat/${candidate.id}`, i18n)}>
      {groupName.length > 0 && <GroupTag size="s" title={groupName} color={groupColor} />}
      <TopResult>
        <Crown alt="crownLogo" src={`${process.env.IMAGES_PREFIX}/crown.svg`} />
        <RoundImage
          image={candidate.image}
          placeholder={`${process.env.IMAGES_PREFIX}/man.png`}
          alt={`Ehdokas ${candidate.firstName} ${candidate.lastName}`}
        />
        <ScorePill>{Math.round(score.score * 100)}%</ScorePill>
      </TopResult>
      {candidate.electionNumber && <CandidateNr smaller={candidate.electionNumber.length > 3}>{candidate.electionNumber}</CandidateNr>}
      <Name>{candidate.firstName} {candidate.lastName}</Name>
      {showCategories
        && (
          <DialsWrapper>
            <h3>{t('resultsPage.categoryHeader')}</h3>
            <Dials>
              {Object
                .keys(score.categoryScores!)
                .map((categoryId) => {
                  const result = score.categoryScores![categoryId]
                  const category = categories.find(c => c.id === categoryId)
                  const title = category ? category[`name${i18n.language === 'fi' ? '' : `_${i18n.language}`}`] : 'Muut'
                  return result ? (
                    <ResultDial key={categoryId} percentage={result} title={title} />
                  )
                    : undefined
                })
              }
            </Dials>
          </DialsWrapper>
        )}
    </LinkCard>
  )
}

const Crown = styled.img`
  height: 80px !important;
  right: -12px;
  position: absolute;
  top: -57px;
  transform: rotate(25deg);
`
const Dials = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media only screen and ${device.mobileS} {
    justify-content: center;
    ${ResultDial} {
      margin: 6px;
    }
  }
`
const DialsWrapper = styled.div`
  width: 100%;
  h3 {
    color: #ced4da;
    font-size: 14px;
  }
`

const ScorePill = styled.div`
  border-radius: 100vh;
  display: inline-block;
  color: ${theme.color.black};
  font-weight: 600;
  font-size: 32px;
  font-family: ${theme.font.number};
  background-color: #fff;
  padding: 5px 10px;
  z-index: 99;
`

const Name = styled.h3`
  color: ${theme.color.black};
  font-weight: 600;
  font-size: ${theme.fontSize.large};
  text-align: center;
`

const TopResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const TopResultCard = styled(ResultCardElement)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;

  ${ScorePill} {
    margin-top: -40px;
  }

  ${TopResult} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${GroupTag} {
    margin: 10px;
    position: absolute;
    top: 0;
    left: 0;
    @media only screen and ${device.mobileM} {
      height: 130px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      font-size: ${theme.fontSize.medium};
      border-radius: 0 0 10px 10px;
      justify-content: center;
      padding: 0;
      position: relative;
      p {
        margin: 15px !important;
      }
    }
  }

  ${RoundImage} {
    margin-bottom: 10px;
    display: flex;
    align-self: center;
    width: 140px !important;
    height: auto !important;
    border: 1px solid ${theme.color.grey.default};
  }
`

const CandidateNr = styled.div<({ smaller?: boolean }) >`
  position: absolute;
  background-color: ${theme.color.grey.light};
  font-weight: bold;
  ${props => props.smaller ? 'font-size: 14px !important;' : 'font-size: 16px;'};
  border-radius: 50%;
  border: 2px solid white;
  min-width: 50px;
  min-height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -13px;
  right: -13px;
  padding: 3px;

  @media only screen and ${device.mobileM} {
    display: flex;
    position: relative;
    width: max-content;
    top: 0;
    right: 0;
    order: 2;
  }
`
