/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/jsx-props-no-spreading */
import { Route, Link, useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { theme } from '../theme'
import { useData } from '../stores'
import { Button } from '../elements/Button'
import { toLanguagePath } from '../utils/I18nHelper'
import { device } from '../theme/deviceSize'
import { wa } from '../utils/Analytics.util'

const availableLanguages = [
  {
    short: 'fi',
    long: 'Suomeksi'
  },
  {
    short: 'se',
    long: 'Svenska'
  },
  {
    short: 'en',
    long: 'In English'
  }
]

export const ElectionRoute = (props: { [x: string]: any, component: any }) => {
  // eslint-disable-next-line react/prop-types
  const { component, logOut, hideNav, ...rest } = props
  const history = useHistory()
  const { electionData, resultStore, answeringDone } = useData(stores => ({
    electionData: stores.dataStore.electionData,
    resultStore: stores.resultStore,
    answeringDone: stores.resultStore.answeringDone
  }))
  const { i18n, t } = useTranslation()
  const ref = React.createRef<HTMLElement>()

  const restart = () => {
    resultStore.clearResults()
    history.push(toLanguagePath('/kysymykset/1', i18n))
  }

  useEffect(() => {
    const blockType = (history.location.pathname === '/' || history.location.pathname.startsWith('/kysymykset')) ?
      'center' :
      'start'

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current!.scrollIntoView({
      behavior: 'smooth',
      block: blockType,
    })
    wa.trackPageview() // To track the first pageview upon load
  }, [props])

  const renderPage = (Component: any, renderProps: any) => (
    <MainWrapper ref={ref}>
      { !hideNav && (
        <Nav>
          <Link to={toLanguagePath('/', i18n)}>
            <h4>{t('questionsPage.toFrontPage')}</h4>
          </Link>
          <Link data-cy="to-candidates-page" to={toLanguagePath('/ehdokkaat', i18n)}>
            <h4>{t('homePage.browseCandidates')}</h4>
          </Link>
          <a onClick={() => restart()}>
            <h4>{t('homePage.restart')}</h4>
          </a>
        </Nav>
      )}
      <Component {...renderProps}/>
      <Footer>
        { !hideNav && (
          <Nav>
            <NavItems>
              <Link data-cy="to-startpage" to={toLanguagePath('/', i18n)}>
                <p>{t('homePage.startPage')}</p>
              </Link>
              <Link data-cy="to-candidates-page" to={toLanguagePath('/ehdokkaat', i18n)}>
                <p>{t('homePage.browseCandidates')}</p>
              </Link>
              <a onClick={() => restart()}>
                <p>{t('homePage.restart')}</p>
              </a>
              {answeringDone &&
                <Link to={toLanguagePath('/tulokset', i18n)}>
                  <p>{t('homePage.results')}</p>
                </Link>
              }
            </NavItems>
          </Nav>
        )}
        <LangsContainer>
          {electionData.election.supportedLanguages && availableLanguages.map(l =>
            (electionData.election.supportedLanguages?.includes(l.short) && l.short !== i18n.language) ?
              <LangLink
                data-cy={l.short}
                key={l.short} onClick={() => {
                  history.push({ search: `?lng=${l.short}` })
                  i18n.changeLanguage(l.short)
                }
              }>{l.long}</LangLink> : '')
          }
        </LangsContainer>
      </Footer>
    </MainWrapper>
  )

  return (
    <Route
      {...rest}
      render={routeProps => renderPage(component!, routeProps)}
    />
  )
}

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0 10px 0;

  h4 {
    margin: 0 15px 0 0 !important;
    cursor: pointer;
    color: ${theme.color.grey.default};
    font-size: ${theme.fontSize.mediumLarge};
    word-wrap: normal;
    word-break: normal;
    line-height: normal;
    @media only screen and (max-width: ${theme.breakPoints.sm}) {
      font-size: ${theme.fontSize.small};
    }
  }

  ${Button} {
    border-radius: 50%;
    font-size: 24px;
    color: ${theme.color.grey.default};
    margin-right: 10px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NavItems = styled.div`
  display: flex;
  a {
    padding: 0 !important;
    margin: 0 6px 0 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${theme.color.grey.default} !important;
    &:hover {
      p {
        color: ${theme.color.black} !important;
      }
    }
  }
  p {
    margin: 0;
  }

  @media only screen and ${device.mobileL} {
    flex-direction: column;
    align-items: center;
    a {
      margin-bottom: 16px;
      font-size: ${theme.fontSize.medium} !important;
    }
  }
`

const Footer = styled.footer`
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${theme.color.grey.light};
  font-size: ${theme.fontSize.small};

  a, a:visited {
  padding-left: 6px;
   color: ${theme.color.grey.light};
   font-size: ${theme.fontSize.small};
   font-weight: 600;
  }

  small,
  small > a {
    color: #e5e8ea !important;
    font-weight: normal;
  }
`
const MainWrapper = styled.main`
  background-color: ${theme.color.white};
  padding: 5px;
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
  @media only screen and ${device.mobileS} {
      max-width: 320px;
  }

  @media only screen and ${device.mobileM} {
      max-width: 375px;
  }

  @media only screen and ${device.mobileL} {
      max-width: 425px;
  }
`

const LangsContainer = styled.div`
  margin: 30px 0;
`

const LangLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
  margin: 5px;
  color: ${theme.color.grey.dark} !important;
`
