import { get, post } from '.'
import { IResult, IGroupResult } from '../types/result.types'

export const getTop10Results = (electionId: string, answerToken: string, areaId?: string) =>
  get<IResult[]>(`election/${electionId}/results/top-10/${areaId ? `area/${areaId}/` : ''}${answerToken.replace(/\|/g, '%7C')}`)

export const getFullResults = (electionId: string, answerToken: string, areaId?: string) => {
  const rootElement = document.getElementById('voting-compass-app')
  const inAdminUi = !!((rootElement && rootElement.getAttribute('in-admin-ui')))
  return post<IResult[]>(`election/${electionId}/results/all/${areaId ? `area/${areaId}/` : ''}
${answerToken.replace(/\|/g, '%7C')}`, { inAdminUi })
}

export const getGroupResults = (electionId: string, answerToken: string, areaId?: string) =>
  get<IGroupResult[]>(`election/${electionId}/results/groups/${areaId ? `area/${areaId}/` : ''}${answerToken.replace(/\|/g, '%7C')}`)
