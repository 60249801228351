import styled from 'styled-components'
import React from 'react'
import { theme, raise } from '../theme'
import { CheckMarkAtom } from '../atoms/CheckMark.atom'
import { device } from '../theme/deviceSize'

interface IProps {
  name: string
  onChange: () => void
  onClick?: () => void
  value: number
  checked?: boolean
  readOnly?: boolean
  'data-cy'?: string
  id?: string
  'aria-label'?: string
}

export const CircleButtonElement: React.FunctionComponent<IProps> = props => (
  <CircleButton readOnly={!!props.readOnly} checked={props.checked}>
    <input
      id={props.id}
      data-cy={props['data-cy']}
      type="radio"
      name={props.name}
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
      onClick={props.onClick}
      aria-label={props['aria-label']}
    />
    {props.checked && <CheckMarkAtom readOnly={props.readOnly} />}
  </CircleButton>
)

const CircleButton = styled.div<{ readOnly?: boolean, checked?: boolean }>`
  border: 2px solid ${theme.color.grey.default};
  background-color: ${props => props.checked ? theme.color.brandColor.mainColor : theme.color.brandColor.secondaryColor};
  width: ${({ readOnly }) => readOnly ? '40px' : '60px'};
  height: ${({ readOnly }) => readOnly ? '40px' : '60px'};
  border-radius: 50%;
  position: relative;
  
  @media only screen and ${device.mobileL} {
    width: 40px;
    height: 40px;
  }  

  @media only screen and ${device.mobileS} {
    width: 30px;
    height: 30px;
  }  



  &:active {
    background-color: ${({ readOnly }) => !readOnly && theme.color.brandColor.mainColor} !important;
  }
  &:focus-within {
    box-shadow: ${raise(2)};
    transform: scale(1.1);      
    transition: transform 260ms ease-out;
  }
  input {
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;
    cursor: ${({ readOnly }) => readOnly ? 'initial' : 'pointer'};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    border: none;
    box-shadow: none;
    background: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      ${raise(3)};
      cursor: ${({ readOnly }) => readOnly ? 'not-allowed' : 'pointer'};
    }
  }
`
