import React from "react"
import styled from "styled-components"
import { getBackgroundColor, getColor, raise, theme } from "../../theme"
import { IQuestionsOption } from "../../types/data.types"
import { hexColorDark } from '../../utils/Color.util'

interface IProps {
  selected?: boolean
  positionNumber?: number
  onClick?: (questionOption: IQuestionsOption) => void
  disabled?: boolean
  option: IQuestionsOption
  readonly?: boolean
  className?: string
  color?: string
  secondaryColor?: string
  optionText: string
}

export const PriorityListOption = ({
  positionNumber,
  onClick,
  disabled,
  option,
  readonly,
  selected,
  className,
  color,
  secondaryColor,
  optionText,
}: IProps) => {
  const getDetailsColor = () => color || theme.color.brandColor.mainColor
  const getSecondaryColor = () =>
    secondaryColor || theme.color.brandColor.secondaryColor
  return (
    <ListOption
      className={className}
      selected={selected}
      readonly={readonly}
      onClick={(e) => {
        e.preventDefault()
        if (onClick) {
          onClick(option)
        }
      }}
      disabled={disabled}
      detailColor={getDetailsColor()}
      secondaryColor={getSecondaryColor()}
    >
      <Number selected={selected}>{positionNumber ?? "-"}</Number>
      <OptionText>{optionText}</OptionText>
    </ListOption>
  )
}

const OptionText = styled.p`
  margin: 0 0 0 8px;
  width: 100%;
  text-align: left;
  font-size: ${theme.fontSize.small};
  &:first-letter {
    text-transform: capitalize;
  }
`
const Number = styled.div<{
  selected?: boolean
}>`
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  border-right: 1px solid ${theme.color.black};
  flex-shrink: 0;
  color: ${({selected}) => hexColorDark(selected ? getBackgroundColor("secondary") : getBackgroundColor("primary")) ? theme.color.black : theme.color.white};
  font-weight: bold;
`

const ListOption = styled.button<{
  selected?: boolean
  disabled?: boolean
  detailColor: string
  readonly?: boolean
  secondaryColor: string
}>`
  width: 100%;
  padding: 8px 8px 8px 4px;
  display: flex;
  align-items: stretch;
  min-height: 40px;
  background-color: ${({ selected }) =>
    selected ? getBackgroundColor("primary") : getBackgroundColor("secondary")};
  -webkit-tap-highlight-color: transparent;

  border-radius: 4px;
  ${OptionText} {
    color: ${({ selected }) =>
      selected ? getColor("primary") : getColor("secondary")};
    ${({ selected }) => selected && "font-weight: bold;"};
  }
  border: ${({ detailColor }) => `1px solid ${detailColor}`};
  cursor: ${({ disabled, readonly }) =>
    disabled || readonly ? "not-allowed" : "pointer"};

  &:hover {
    ${({ disabled, readonly }) => (disabled || readonly ? raise(1) : raise(3))};
  }
`
