import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  dismiss: () => void
  content: string
}

export const Modal: React.FunctionComponent<IProps> = ({ dismiss, content }) => (
  <ModalBackground onClick={dismiss}>
    <ModalContainer>
      <CloseContainer>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="#333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"/>
          <line x1="6" y1="6" x2="18" y2="18"/>
        </svg>
      </CloseContainer>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </ModalContainer>
  </ModalBackground>
)

const ModalBackground = styled.div`
  position: fixed;
  z-index: 99999999999999999999999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div<{ height?: string }>`
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 20px;
  width: 300px;
  position: relative;
  
  p > a, p > a:visited {
    color: #0000EE;
    text-decoration: underline;
  }
`
const CloseContainer = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
  background: none;
  border: none;
  cursor: pointer;
`
