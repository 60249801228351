import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { YesNoButton } from "./AnswerButton"
import { IQuestion } from '../types/data.types'

interface IProps {
  className?: string
  question: IQuestion
  value?: number
  answerToQuestion?: (questionId: string, value: number) => void
  userAnswer?: number
}

export const AnswersYesNo: React.FC<IProps> = ({ question, value, answerToQuestion, className, userAnswer }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(value)
  useEffect(() => {
    setSelectedAnswer(value)
  }, [value])
  
  const answer = (val: number) => {
    if (answerToQuestion) answerToQuestion(question.id, val)
    setSelectedAnswer(val)
  }
  const readOnly = !answerToQuestion
  const { t } = useTranslation()
  
  return <ButtonContainer className={className}>
    <YesNoButton
      data-cy="yes-option"
      name="yesNoChoice"
      value={1}
      onClick={() => answer(1)}
      label={t('questionsPage.yes')}
      intent={selectedAnswer === 1 ? 'primary' : 'secondary'}
      readOnly={readOnly}
      userAnswered={userAnswer === 1}
    />
    <YesNoButton
      data-cy="no-option"
      name="yesNoChoice"
      value={2}
      onClick={() => answer(2)}
      label={t('questionsPage.no')}
      intent={selectedAnswer === 2 ? 'primary' : 'secondary'} 
      readOnly={readOnly}
      userAnswered={userAnswer === 2}
      />
  </ButtonContainer>
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto 80px;
`
