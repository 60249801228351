export const translations = {
  en: {
    translation: {
      homePage: {
        welcomeTo: 'Welcome to the',
        electionCompass: 'election compass!',
        start: 'Start',
        browseCandidates: 'Browse candidates',
        dataNotFound: 'Election data not found',
        restart: 'Start over',
        browseAnswers: 'Browse your answers',
        results: 'Results',
        startPage: 'Startpage',
        selectArea: 'Select area...',
        area: 'Area'
      },
      questionsPage: {
        toFrontPage: 'To startpage',
        previous: 'Previous',
        skip: 'Skip',
        pageNotFound: 'The page you were looking for was not found',
        backToStart: 'Back to start',
        yes: 'Yes',
        no: 'No',
        save: 'Save and continue',
        multiselect: 'Multiselect',
        selectAtleast: 'Select at least',
        selectAtMost: 'Select at most',
        info: 'What is this about?',
        oneToTenDisagree: '',
        oneToTenAgree: '',
        disagree: 'Completely disagree',
        mildlyDisagree: 'Slightly disagree',
        dontKnow: "I don't know",
        mildlyAgree: 'Slightly agree',
        agree: 'Completely agree',
        prioNotDone: 'Select',
        prioDone: 'Ready!',
        prioSelectMostImportant: 'Select the most important option first'
      },
      resultsPage: {
        filterCandidates: 'Filter candidates',
        age: 'Age',
        sex: 'Sex',
        group: 'Group',
        area: 'Area',
        female: 'Woman',
        male: 'Man',
        other: 'Other',
        belowThirty: 'Below 30 y/o',
        thirtyToFifty: '30 - 50 y/o',
        aboveFifty: 'Above 50 y/o',
        favourite: 'Favourite',
        restart: 'Start over',
        clearFilters: 'Clear filters',
        noSimilarCandidates: 'No similarities with candidates',
        partyComparison: 'Party comparison',
        categoryHeader: 'Relevance by theme',
        showMoreResults: 'Show more results',
        partyResultInfo: 'Party comparison is calculated from the questions answered on a "Completely disagree" - "Completely agree" scale',
        leastSuitableCandidates: 'Least suitable candidates',
        alliance: 'Electoral alliance',
        circle: 'Electoral circle'
      },
      candidatePage: {
        candidateInfo: 'Candidate information',
        candidateAnswers: 'Candidate answers',
        age: 'Age',
        sex: 'Sex',
        area: 'Area',
        socialMedia: 'Social media',
        candidateNumber: 'Candidate number',
        you: 'You',
        allianceHeader: 'Electoral alliance',
        circleHeader: 'Electoral circle'
      },
      candidatesPage: {
        search: 'Search candidates...',
        noGroup: 'No group'
      },
      closedForPublicPage: {
        header: 'The elections are closed'
      }
    }
  },
  fi: {
    translation: {
      homePage: {
        welcomeTo: 'Tervetuloa',
        electionCompass: 'vaalikoneeseen!',
        start: 'Aloita',
        browseCandidates: 'Selaa ehdokkaita',
        dataNotFound: 'Vaalidataa ei löytynt',
        restart: 'Aloita alusta',
        browseAnswers: 'Selaa vastauksiasi',
        results: 'Tulokset',
        startPage: 'Aloitussivu',
        selectArea: 'Valitse alue...',
        area: 'Alue'
      },
      questionsPage: {
        toFrontPage: 'Aloitussivulle',
        previous: 'Edellinen',
        skip: 'Ohita',
        pageNotFound: 'Hakemaasi sivua ei löytynyt',
        backToStart: 'Palaa alkuun',
        yes: 'Kyllä',
        no: 'Ei',
        save: 'Tallenna ja jatka',
        multiselect: 'Monivalinta',
        selectAtleast: 'Valitse vähintään',
        selectAtMost: 'Valitse enintään',
        info: 'Mistä on kyse?',
        oneToTenDisagree: '',
        oneToTenAgree: '',
        disagree: 'Täysin eri mieltä',
        mildlyDisagree: 'Jokseenkin eri mieltä',
        dontKnow: 'En osaa sanoa',
        mildlyAgree: 'Jokseenkin samaa mieltä',
        agree: 'Täysin samaa mieltä',
        prioNotDone: 'Valitse vielä',
        prioDone: 'Valmis!',
        prioSelectMostImportant: 'Valitse tärkein vaihtoehto ensin'
      },
      resultsPage: {
        filterCandidates: 'Rajaa ehdokkaita',
        age: 'Ikä',
        sex: 'Sukupuoli',
        group: 'Ryhmittymä',
        area: 'Vaalipiiri',
        female: 'Nainen',
        male: 'Mies',
        other: 'Muu',
        belowThirty: 'Alle 30 v',
        thirtyToFifty: '30 - 50 v',
        aboveFifty: 'Yli 50 v',
        favourite: 'Suosikkisi',
        restart: 'Aloita alusta',
        clearFilters: 'Tyhjennä rajaukset',
        noSimilarCandidates: 'Ei yhtäläisyyksiä ehdokkaiden kanssa',
        partyComparison: 'Ehdokasryhmittymien sopivuus',
        categoryHeader: 'Sopivuus teemoittain',
        showMoreResults: 'Näytä lisää tuloksia',
        partyResultInfo: 'Ehdokasryhmittymien sopivuus lasketaan kysymyksistä johon vastataan "Täysin eri mieltä" - "Täysin samaa mieltä" asteikolla',
        leastSuitableCandidates: 'Vähiten sopivat ehdokkaat',
        alliance: 'Vaaliliitto',
        circle: 'Vaalirengas'
      },
      candidatePage: {
        candidateInfo: 'Ehdokkaan tiedot',
        candidateAnswers: 'Ehdokkaan vastaukset',
        age: 'Ikä',
        sex: 'Sukupuoli',
        area: 'Vaalipiiri',
        socialMedia: 'Sosiaalinen media',
        candidateNumber: 'Ehdokasnumero',
        you: 'Sinä',
        allianceHeader: 'Vaaliliitto',
        circleHeader: 'Vaalirengas'
      },
      candidatesPage: {
        search: 'Hae ehdokasta...',
        noGroup: 'Ryhmättömät'
      },
      closedForPublicPage: {
        header: 'Vaalit on suljettu'
      }
    }
  },
  se: {
    translation: {
      homePage: {
        welcomeTo: 'Välkommen till',
        electionCompass: 'valkompassen!',
        start: 'Börja',
        browseCandidates: 'Bläddra kandidater',
        dataNotFound: 'Val data hittades ej',
        restart: 'Börja om',
        browseAnswers: 'Bläddra dina svar',
        results: 'Resultaten',
        startPage: 'Startsida',
        selectArea: 'Välja område...',
        area: 'Område'
      },
      questionsPage: {
        toFrontPage: 'Till startsidan',
        previous: 'Föregående',
        skip: 'Förbi',
        pageNotFound: 'Sidan du letade efter hittades inte',
        backToStart: 'Tillbaka till början',
        yes: 'Ja',
        no: 'Nej',
        save: 'Spara',
        multiselect: 'Flerval',
        selectAtleast: 'Välj Minst',
        selectAtMost: 'Välj högst',
        info: 'Vad handlar det om?',
        oneToTenDisagree: '',
        oneToTenAgree: '',
        disagree: 'Helt av annan åsikt',
        mildlyDisagree: 'Håller inte helt med',
        dontKnow: 'Kan inte säga',
        mildlyAgree: 'Överens till viss del',
        agree: 'Helt av samma åsikt',
        prioNotDone: 'Välj ännu',
        prioDone: 'Du är klar!',
        prioSelectMostImportant: 'Välj det viktigaste alternativet först'
      },
      resultsPage: {
        filterCandidates: 'Filtrera kandidater',
        age: 'Ålder',
        sex: 'Kön',
        group: 'Grupp',
        area: 'Område',
        female: 'Kvinna',
        male: 'Man',
        other: 'Annat alternativ',
        belowThirty: 'Under 30 år',
        thirtyToFifty: '30 - 50 år',
        aboveFifty: 'över 50 år',
        favourite: 'Favorit',
        restart: 'Börja om',
        clearFilters: 'Töm filtreringen',
        noSimilarCandidates: 'Inga likheter med kandidaterna',
        partyComparison: 'Gruppernas passlighet',
        categoryHeader: 'Lämplighet enligt teman',
        showMoreResults: 'Visa mera resultat',
        partyResultInfo: 'Kandidatgruppernas behörighet beräknas utifrån frågorna som besvaras i en skala "Helt av annan åsikt" - "Helt av samma åsikt"',
        leastSuitableCandidates: 'Minst passande kandidater',
        alliance: 'Valallians',
        circle: 'Valring'
      },
      candidatePage: {
        candidateInfo: 'Kandidatens information',
        candidateAnswers: 'Kandidatens svart',
        age: 'Ålder',
        sex: 'Kön',
        area: 'Område',
        socialMedia: 'Social media',
        candidateNumber: 'Kandidatnummer',
        you: 'Ditt svar',
        allianceHeader: 'Valallians',
        circleHeader: 'Valring'
      },
      candidatesPage: {
        search: 'Sök kandidat...',
        noGroup: 'Ingen grupp'
      },
      closedForPublicPage: {
        header: 'Valet är stängt'
      }

    }
  }
}
