import React from 'react'
import styled from 'styled-components'
import { theme, raise } from '../theme'
import { ICandidate, IGroup } from '../types/data.types'
import { Card } from './Card'
import { RoundImage } from './Image'
import { hexColorDark } from '../utils/Color.util'
import { device } from '../theme/deviceSize'

interface IProps {
  candidate: ICandidate
  group?: IGroup
  onClick?: () => void
  className?: string
}

export const CandidateGridCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const { candidate, onClick, group } = props

  return (
    <CardWrapper key={candidate.id} onClick={onClick && onClick} className={props.className}>
      <RoundImage image={candidate.image}
        placeholder={`${process.env.IMAGES_PREFIX}/man.png`}
        alt={`Ehdokas ${candidate.firstName} ${candidate.lastName}`} size="100px" />
      <PartyPill bgColor={theme.color.grey.light} hidden={!candidate.electionNumber}>
        <span>{candidate.electionNumber}</span>
      </PartyPill>
      <CandidateName>{`${candidate.firstName} ${candidate.lastName}`}</CandidateName>
      <PartyPill bgColor={group && (group.color || theme.color.grey.light)} hidden={!group}>
        <span>{group?.abbreviation}</span>
      </PartyPill>
    </CardWrapper>
  )
}

const PartyPill = styled.div<{ bgColor?: string, borderColor?: string, hidden: boolean }>`
  padding: 2px 5px;
  border-radius: 25px;
  font-size: ${theme.fontSize.xs};
  background-color: ${({ bgColor }) => bgColor || theme.color.grey.light};
  color: ${({ bgColor }) => bgColor && hexColorDark(bgColor) ? theme.color.white : theme.color.black};
  justify-content: center;
  display: flex;
  width: fit-content;
  align-self: center;
  position: relative;
  border: 2px solid white;
  opacity: ${({ hidden }) => hidden && 0};
  min-width: 25px;
`

const CardWrapper = styled(Card)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: auto;
  width: calc(33% - 20px);
  margin: 10px;
  transition: box-shadow 0.2s;
  align-items: center;
  padding: 16px;

  ${PartyPill} {
    top: -12px;
    @media only screen and (max-width: ${theme.breakPoints.sm}) {
      padding: 3px;
      > span {
        font-size: ${theme.fontSize.xs};
      }
    }
  }

  > span {
    text-align: center;
    font-size: ${theme.fontSize.small};
    font-weight: 600;
    white-space: normal;
  }
  &:hover {
    cursor: pointer;
    ${raise(3)};
  }

  @media only screen and (max-width: ${theme.breakPoints.sm}) {
    width: calc(50% - 20px);
    padding: 0.5rem;
    img {
      width: 80px;
      height: 80px;
    }
    > span {
      font-size: ${theme.fontSize.xs};
    }
  }

  @media only screen and ${device.mobileS} {
    width: 100%;
    > span {
      font-size: ${theme.fontSize.medium};
  }
`
const CandidateName = styled.div`
  margin-bottom: 10px;
`