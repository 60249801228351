import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { device } from '../theme/deviceSize'

const CheckMark: FunctionComponent<{ className?: string }> = ({ className }) => <svg aria-hidden className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" >
    <polyline className="path check" fill="none" stroke={theme.color.white} strokeWidth="6" strokeLinecap="round"
      strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
  </svg>

export const CheckMarkAtom = styled(CheckMark) <({ readOnly?: boolean }) >`
   svg {
    width: ${({ readOnly }) => readOnly ? '38px' : '58px'};
    display: block;
    margin: 0;
  }

  @media only screen and ${device.mobileL} {
    width: 38px;
    height: 38px;
  }

  @media only screen and ${device.mobileS} {
    width: 27px;
    height: 27px;
  }
 
  &:active {
    background-color: ${({ readOnly }) => !readOnly && theme.color.orange.default} !important;
  }
`
