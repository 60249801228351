import React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { hexColorDark } from '../utils/Color.util'

type PillSize = 's' | 'm'

interface IProps {
  className?: string
  color?: string
  title: string
  // eslint-disable-next-line react/no-unused-prop-types
  size?: PillSize
}

const GroupTagElement: React.FC<IProps> = (props: IProps) => (
  <div className={props.className} style={{ backgroundColor: props.color }}>
    <p>{props.title}</p>
  </div>
)

const getSize = (size?: PillSize) => {
  switch (size) {
    case 's':
      return '20px'
    case 'm':
      return '50px'
    default:
      return '20px'
  }
}

export const GroupTag = styled(GroupTagElement)`
  background-color: ${props => props.color ? props.color : theme.color.blue.light};
  text-transform: uppercase;
  border-radius: 25px;
  color: ${props => props.color && hexColorDark(props.color) ? theme.color.white : theme.color.black};
  font-weight: 600;
  min-width: 50px;
  height: ${props => getSize(props.size)};
  font-size: ${theme.fontSize.xs};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0 !important;
  }
`
