import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from '../theme'

export const ClosedIndicator: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return <IndicatorContainer>
    <MessageWrapper>
      <h3>{t('closedForPublicPage.header')}</h3>
    </MessageWrapper>
  </IndicatorContainer>
}

const IndicatorContainer = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  min-height: 400px;
`

const MessageWrapper = styled.div`
  display: flex;
  background-color: ${theme.color.white};
  width: 100%;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  background-image: url(${process.env.IMAGES_PREFIX}/election-small.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${theme.color.black};
  align-items: center;

  h3 {
    background-color: aliceblue;
    width: 75%;
    padding: 10px;
    margin: 20px 80px;
  }
`
