import React from 'react'
import { DataStore } from './DataStore'
import { ResultStore } from './ResultStore'
import { SessionStore } from './SessionStore'
import { IStores, StoreContext } from './index'

const createStores = (electionId: string): IStores => ({
  dataStore: new DataStore(electionId),
  resultStore: new ResultStore(electionId),
  sessionStore: new SessionStore()
})

export const StoreProvider: React.FC<{ electionId: string }> = ({ children, electionId }) => {
  const stores = createStores(electionId)
  return <StoreContext.Provider value={stores}>{ children }</StoreContext.Provider>
}
