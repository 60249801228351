import React, { useEffect, useState } from 'react'
import { HashRouter, Redirect, Switch } from 'react-router-dom'
import WebFont from 'webfontloader'
import { initReactI18next } from 'react-i18next'
import styled from 'styled-components'
import i18n from 'i18next'
import { CandidatesPage } from './pages/Candidates.page'
import { Loader } from './elements/Loader'
import { ElectionRoute } from './routes/election.route'
import { HomePage } from './pages/Home.page'
import { CandidatePage } from './pages/Candidate.page'
import { QuestionsPage } from './pages/Questions.page'
import { theme } from './theme'
import { ResultPage } from './pages/Result.page'
import { useData } from './stores'
import { StoreProvider } from './stores/context'
import { initi18nOverrides } from './i18n/i18n'
import { translations } from './translations'

export const App = (app: string, lng: string) => (
  <Styled>
    <StoreProvider electionId={app} >
      <Application electionId={app} lng={lng} />
    </StoreProvider>
  </Styled>
)

const Application = ({ electionId, lng }: {electionId: string; lng: string}) => {
  const dataStore = useData(stores => stores.dataStore)
  const sessionStore = useData(stores => stores.sessionStore)
  const resultStore = useData(stores => stores.resultStore)

  const [loadingFonts, setLoadingFonts] = useState(true)

  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng,
    fallbackLng: "fi",
    interpolation: {
      escapeValue: false
    },
    defaultNS: "overrides",
    fallbackNS: "translation"
  })

  useEffect(() => {
    WebFont.load({
      classes: false,
      google: {
        families: ['Lato|Open+Sans:300,400,500,600']
      },
      timeout: 1000,
      active: () => setLoadingFonts(false),
      inactive: () => setLoadingFonts(false)
    })

    async function setTranslationOverrides() {
      try {
        await initi18nOverrides(electionId)
      } finally {
        const startLang = window.location.href.split('?lng=').length > 1 ?
        window.location.href.split('?lng=')[1] : dataStore.electionData.election.mainLanguage
        i18n.changeLanguage(startLang || 'fi')
      }
    }

    async function loadBaseData() {    
      const electionData = await dataStore.loadElectionData()
      const loadPromises = [dataStore.loadAreas()]
      const presetAreaId = document.getElementById('voting-compass-app')!.getAttribute('area-id')
      const hasAreaSelector = document.getElementById('voting-compass-app')!.getAttribute('show-area-selector')

      if (presetAreaId) {
        loadPromises.push(dataStore.loadCandidates(presetAreaId))
      } else if (!hasAreaSelector) {
        loadPromises.push(dataStore.loadCandidates())
      } else if (resultStore.areaOptions.selectedArea) {
        loadPromises.push(dataStore.loadCandidates(resultStore.areaOptions.selectedArea))
      }
      
      // todo: bug? loadPromises is already an array so this wouldn't wait
      await Promise.all([loadPromises])
      if (electionData?.election.brandColor) {
        theme.color.brandColor = electionData.election.brandColor
      }
      dataStore.setLoading(false)
    }

    async function webscaleAnalytics() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.waConfig = {
        domainId: process.env.DOMAIN_ID,
        analyticsDomain: 'analytics.webscale.fi',
        sessionId: sessionStore.sessionID,
        useCookies: false
      }
      const script = document.createElement('script')
      script.src = 'https://analytics.webscale.fi/wa/wa.js'
      script.async = true
      script.onload = function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore does not like the !-notation
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !function (e, t, s, i) {
          const n = 'WebscaleAnalyticsEmbed'
          const o = e.getElementsByTagName('script')[0]
          const d = /^http:/.test(e.location.href) ? 'http:' : 'https:'
          const a = d + i
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-sequences
          if (/^\/{2}/.test(i) && (a), window[n] && window[n].initialized) window[n].process && window[n].process()
          else if (!e.getElementById(s)) {
            const r = e.createElement('script')
            r.async = true
            r.id = s
            r.src = i
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore we are aware that it might be a null, it's on purpose
            o.parentNode.insertBefore(r, o)
          }
        }(document, 0, 'webscale-analytics-async', 'https://analytics.webscale.fi/wa/wa.js')
      }
      document.head.appendChild(script)
    }
    setTranslationOverrides()
    loadBaseData()
    webscaleAnalytics()
  }, [])

  return (
    (dataStore.loading && loadingFonts) ?
    <Loader /> : <HashRouter basename="/">
        <Switch>
          <ElectionRoute exact path="/" hideNav component={HomePage} />
          <ElectionRoute exact path="/ehdokkaat" component={CandidatesPage} />
          <ElectionRoute exact path="/kysymykset/:questionNumber" component={QuestionsPage} />
          <ElectionRoute exact path="/tulokset" component={ResultPage} />
          <ElectionRoute exact path="/ehdokkaat/:candidateId" component={CandidatePage} />
          <Redirect to="/" />
        </Switch>
      </HashRouter>
  )
}

const Styled = styled.div`
  padding: 0;
  background-color: white;
  font-family: ${theme.font.text};
  color: ${theme.color.black};
  min-height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }
  ul, ol {
    list-style: none;
  }
  a, a:visited {
    color: inherit;
    text-decoration: none;
  }
`
