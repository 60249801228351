import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../theme'

interface IProps {
  className?: string
  image?: string
  alt: string
  // eslint-disable-next-line react/no-unused-prop-types
  size?: string
  placeholder?: string
}

const ImageElement: FunctionComponent<IProps> = ({ className, image, alt, placeholder }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <img className={className}
      style={{ opacity: loaded ? 1 : 0 }}
      src={image ? `${process.env.BUCKET_URL}${image.replace('original', 'resized')}_normal` : placeholder}
      onLoad={() => setLoaded(true)}
      alt={alt} />
  )
}

export const RectangularImage = styled(ImageElement)`
  background-color: ${theme.color.white};
  width: ${({ size }) => size || '120px'};
  height: ${({ size }) => size || '120px'};
  transition: opacity .3s ease-in;
`
export const RoundImage = styled(ImageElement)`
  background-color: ${theme.color.white};
  width: ${({ size }) => size || '120px'};
  height: ${({ size }) => size || '120px'};
  border-radius: 50%;
  flex-shrink: 0;
  transition: opacity .3s ease-in;
`
