import i18n from "i18next"
import { getTranslations } from "../api/data.api"


export const initi18nOverrides = async (electionId?: string) => {
  const translationsOverrides = await getTranslations(electionId)

  i18n.addResourceBundle("fi", "overrides", translationsOverrides.fi)
  i18n.addResourceBundle("se", "overrides", translationsOverrides.se)
  i18n.addResourceBundle("en", "overrides", translationsOverrides.en)
}
export default i18n
