import styled from 'styled-components'
import React from 'react'
import { theme } from '../theme'
import { useData } from '../stores'

interface IProps {
  className?: string
  total: number
  current: number
}

const ProgressBarElement: React.FunctionComponent<IProps> = ({ className, total, current }) => {
  const { electionData } = useData(({ dataStore }) => ({
    electionData: dataStore.electionData
  }))
  return (
    <div className={className}>
      { [...Array(total).keys()].map(i => i <= current ? <FilledBar color={electionData.election.brandColor ? 
        electionData.election.brandColor.mainColor : theme.color.orange.default} key={i} /> : <EmptyBar key={i}/>) } 
    </div>
  )
}

const EmptyBar = styled.div`
  height: 6px;
  background-color: ${theme.color.grey.light};
  margin-right: 2px;

  &:last-child {
  margin-right: 0px;
  }
`
const FilledBar = styled(EmptyBar)`
  background-color: ${props => props.color};
`
export const ProgressBar = styled(ProgressBarElement)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  ${EmptyBar} {
    width: ${props => 100 / props.total}%;
  }
`
