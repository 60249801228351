import { action, computed, observable } from 'mobx'

import * as resultApi from '../api/result.api'
import { IResult, IGroupResult } from '../types/result.types'
import { IMappedAnswer } from '../types/data.types'

const RESULT_KEY_PREFIX = 'ws-electioncompass'

type AreaOptions = {
  showAreaDropdown: string | undefined;
  presetAreaId: string | undefined;
  selectedArea: string | undefined;
}

export class ResultStore {
  readonly electionId: string

  readonly storageKey: string

  @observable userAnswer: IMappedAnswer = {}

  @observable results: IResult[] = []

  @observable groupResults: IGroupResult[] = []

  @observable answeringDone: boolean = sessionStorage.getItem('answeringDone') ?
    sessionStorage.getItem('answeringDone') === 'true' : false

  @observable areaOptions: AreaOptions = {
    showAreaDropdown: document.getElementById('voting-compass-app')!.getAttribute('show-area-selector') || undefined,
    presetAreaId: document.getElementById('voting-compass-app')!.getAttribute('area-id') || undefined,
    selectedArea: sessionStorage.getItem('selectedArea') ? sessionStorage.getItem('selectedArea')! : undefined
  }

  constructor(electionId: string) {
    this.electionId = electionId
    this.storageKey = `${RESULT_KEY_PREFIX}-${electionId}`
    this.userAnswer = JSON.parse(sessionStorage.getItem(this.storageKey) || '{}')

    if (!this.areaOptions.showAreaDropdown && !this.areaOptions.presetAreaId) {
      this.clearSelectedArea()
    }
    if (this.areaOptions.presetAreaId) {
      this.setSelectedArea(this.areaOptions.presetAreaId)
    }
  }

  @action clearResults = () => {
    this.results = []
    this.groupResults = []
    this.userAnswer = {}
    sessionStorage.setItem(this.storageKey, JSON.stringify({}))
    sessionStorage.setItem('answeringDone', 'false')
    this.answeringDone = false
  }

  @action setAnswerToQuestion(questionId: string, answer: number, multiAnswer?: number[]) {
    const multiAnswersExists = multiAnswer
    const multiAnswerFormat = () => {
      if (multiAnswersExists && multiAnswer!.length === 1) {
        return multiAnswer!.concat('' as any).join('-')
      }
      return multiAnswer!.join('-')
    }

    // If user removes all answer for multivalue questions, and saves: remove the question from answer obejct
    if (multiAnswer && multiAnswer.length === 0 && this.userAnswer[questionId]) {
      delete this.userAnswer[questionId]
    } else {
      this.userAnswer = {
        ...this.userAnswer, [questionId]:
          multiAnswersExists ? multiAnswerFormat() : answer
      }
    }
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.userAnswer))
  }

  @action setSelectedArea(selectedArea: string) {
    sessionStorage.setItem('selectedArea', selectedArea)
    this.areaOptions = { ...this.areaOptions, selectedArea }
  }

  // eslint-disable-next-line class-methods-use-this
  @action clearSelectedArea() {
    sessionStorage.removeItem('selectedArea')
  }

  @action async getTop10Results(answerToken: string) {
    this.results = await resultApi.getTop10Results(this.electionId, answerToken, this.areaOptions.selectedArea)
  }

  @action async getAllResults(answerToken: string) {
    this.results = []
    this.results = await resultApi.getFullResults(this.electionId, answerToken, this.areaOptions.selectedArea)
    sessionStorage.setItem('answeringDone', 'true')
    this.answeringDone = true
  }

  @action async getGroupResults(answerToken: string) {
    this.groupResults = await resultApi.getGroupResults(this.electionId, answerToken, this.areaOptions.selectedArea)
  }

  /*
  * Token format q[question id]a[answer value]i?
  * Format with multiple answer value q[question id]a[value1-value2-value3]i?
  */
  @computed get answerToken() {
    return Object.keys(this.userAnswer)
      .reduce((token, qId) => `${token}${qId}|${this.userAnswer[qId]}`, '')
  }

  getUserAnswer(questionId?: string) {
    return this.userAnswer[questionId || '']
  }

}
