import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {  raise  } from '../theme'

export const LinkCard = styled(Link)`
  padding: 1.5rem;
  position: relative;
  background-color: #fff;
  border-radius: 0px;
  min-height: 100px;
  ${raise(1)};
  width: 100%;
`
