import * as React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { LoaderIconAtom } from '../atoms/LoaderIcon.atom'

interface IProps {
  height?: string
}

export const Loader: React.FunctionComponent<IProps> = props => (
  <LoadContainer height={props.height}>
    <LoaderIconAtom height={64} color={theme.color.grey.dark} />
  </LoadContainer>
)

const LoadContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  height: ${props => props.height || '100%'};

  svg {
    fill: ${theme.color.grey.dark} !important;
  }
`
