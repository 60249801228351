import { get } from '.'
import { ICandidate, IElectionData, IArea, IDetailedCandidate, IGroup } from '../types/data.types'

export const getElectionData = (electionId: string) =>
  get<IElectionData>(`election/${electionId}`)

export const getCandidates = (electionId: string, areaId?: string) =>
  get<ICandidate[]>(`election/${electionId}/candidates${areaId ? `/area/${areaId}` : ''}`)

export const getCandidateById = (electionId: string, candidateId: string) =>
  get<IDetailedCandidate>(`election/${electionId}/candidates/${candidateId}`)

export const getAreas = (electionId: string) => get<IArea[]>(`election/${electionId}/areas`)

export const getBgAnswersByQuestionId = (electionId: string, questionId: string) => get<any[]>(`election/${electionId}/bg-questions/${questionId}/answers`)

export const getGroups = (electionId: string) => 
  get<IGroup>(`election/${electionId}/candidates/${electionId}`)

export const getTranslations = (electionId?: string) => get<Record<string, unknown>>(`election/${electionId}/translations`)