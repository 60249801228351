import i18n from 'i18next'

export const parseText = (text: string) => {
  // Match all {{ korostus: **** }}
  const bolded = text.match(/\{\{ *korostus:.+?\}\}/g)
  // Match all {{ teksti: ****, linkki: **** }}
  const links = text.match(/\{\{ *teksti:.+?linkki:.+?\}\}/g)
  let parsedText = text
  if (links && links.length > 0) {
    links.forEach((link: string) => {
      // Get text of link
      const linkText = link.split('teksti:')[1].split('linkki:')[0].trim().replace(/,$/, '')
      // Get link address
      const linkAddress = link.split('linkki:')[1].replace(/\}\}$/, '').trim()
      parsedText = parsedText.replace(link, `<a href=${linkAddress} target=_blank>${linkText}</a> `)
    })
  }

  if (bolded && bolded.length > 0) {

    bolded.forEach((bold: string) => {
      // Get text of link
      const boldText = bold.split('korostus:')[1].trim().replace(/\}\}$/, ' ')
      parsedText = parsedText.replace(bold, `<b>${boldText}</b>`)
    })
  }
  return parsedText
}

export const getLocalizedName = (entityWithName: any) => {
  const abbrev = i18n.language === 'fi' ? '' : `_${i18n.language}`
  return entityWithName[`name${abbrev}`] || entityWithName.name
}

export const getLocalizedText = (entityWithTexts: any) => {
  const abbrev = i18n.language === 'fi' ? '' : `_${i18n.language}`
  return entityWithTexts[`text${abbrev}`] || entityWithTexts.text
}
