import styled from 'styled-components'
import { theme } from '../theme'

export const DropdownOption = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  color: ${theme.color.grey.dark};
  flex-shrink: 0;
  background-color: ${props => props.isSelected ? `${theme.color.grey.light}` : 'inherit'};
  
  &:hover {
    background-color: rgba(100,100,100,0.1);
  }
`
