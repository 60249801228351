import React from 'react'
import styled from 'styled-components'
import { GroupTag } from '../atoms/GroupTag.atom'
import { InfoTooltip } from '../atoms/InfoTooltip'
import { theme } from '../theme'
import { device } from '../theme/deviceSize'
import { IGroup } from '../types/data.types'

interface IProps {
  title?: string
  candidateGroup?: IGroup
  infoText?: string
}

export const ResultContentCard: React.FC<IProps> = ({ candidateGroup, title, children, infoText }) => (
    <ResultWrapper>
      {(title || candidateGroup) &&
        <ContainerHeader>
          {title && <ResultPosition>{title}</ResultPosition>}{infoText && <GroupInfo title={infoText}/>}
          {candidateGroup && <GroupTag size="s" title={candidateGroup.abbreviation} color={candidateGroup.color}/>}
        </ContainerHeader>
      }
      {children}
    </ResultWrapper>
  )

const GroupInfo = styled(InfoTooltip)`
  float: right;
`

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ResultWrapper = styled.div`
  border-radius: 8px;
  background-color: #f6f9fc;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  @media only screen and ${device.mobileL} {
    margin-bottom: 1.0rem;
  }
`

const ResultPosition = styled.h3`
  color: ${theme.color.grey.dark};
  margin: 4px;
  font-size: 24px;
`
