/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from '../elements/Button'
import { useData } from '../stores'
import { ResultCard } from '../cards/ResultCard'
import { TopResultCard } from '../cards/TopResultCard'
import { Loader } from '../elements/Loader'
import { FilterCard, IResultCandidate } from '../cards/FilterCard'
import { device } from '../theme/deviceSize'
import { toLanguagePath } from '../utils/I18nHelper'
import { ICandidate } from '../types/data.types'
import { IResult } from '../types/result.types'
import { GroupSimilaritiesCard } from '../cards/GroupSimilaritiesCard'
import { ResultContentCard } from '../elements/ResultContentCard'
import { theme } from '../theme'

export const ResultPage: React.FC = () => {
  const { i18n, t } = useTranslation()
  const history = useHistory()

  const { results, candidates, answerToken, resultStore, categories, groups, groupResults, electionData } =
    useData(({ dataStore, resultStore }) => ({
      resultStore,
      results: resultStore.results,
      groupResults: resultStore.groupResults,
      candidates: dataStore.candidates,
      answerToken: resultStore.answerToken,
      categories: dataStore.electionData.categories,
      electionData: dataStore.electionData,
      groups: dataStore.electionData.groups,
      alliances: dataStore.electionData.alliances
    }))

  useEffect(() => {
    if (!answerToken) {
      history.push(toLanguagePath('/', i18n))
    }
    resultStore.getAllResults(answerToken)
    resultStore.getGroupResults(answerToken)
  }, [])

  useEffect(() => {
    setFilteredResults(filterableCandidates() || [])
  }, [results])

  const [filteredResults, setFilteredResults] = useState<IResultCandidate[]>([])
  const [resultsLimit, setResultsLimit] = useState(10)

  const getCandidate = (candidateId?: string): ICandidate | undefined => {
    if (candidateId) {
      return (candidates && candidates.find(c => c.id === candidateId))
    }
    return undefined
  }

  const getGroup = (groupId: string | undefined) => groups.find(g => g.id === groupId)

  const filterableCandidates = () => results.map((r) => ({
      ...getCandidate(r.candidateId),
      ...r
    })) as IResultCandidate[]

  return (
    results.length > 0 ? (
      <PageContainer>
        <FilterCard
          filteredCandidatesCallback={filteredResults => setFilteredResults(filteredResults)}
          candidates={filterableCandidates()}
        />
          <>
            {filteredResults.length > 0 ?
              <ResultContentCard key={filteredResults[0].candidateId} title={`#1 ${t('resultsPage.favourite')}`}>
                    <TopResultCard
                      candidate={filteredResults[0]}
                      categories={categories}
                      score={{
                        candidateId: filteredResults[0].id,
                        categoryStore: filteredResults[0].categoryScores,
                        score: filteredResults[0].score
                      } as IResult} />
                  </ResultContentCard> :
              <h1>
                {t('resultsPage.noSimilarCandidates')}
              </h1>
            }
            <GroupSimilaritiesCard groups={groups} groupResults={groupResults} />
            {
              filteredResults.slice(1, resultsLimit).map((candidate, index) => {
                const candidateGroup = getGroup(candidate.groupId)
                return candidate && (
                  <ResultContentCard
                    key={`${candidate.candidateId}`}
                    title={`#${index + 2}`}
                    candidateGroup={candidateGroup}
                  >
                    <ResultCard score={Number(candidate.score)} candidate={candidate} />
                  </ResultContentCard>
                )
              })
            }
            {
              resultsLimit < candidates.length &&
              <ShowMoreButton
                color={electionData.election.brandColor?.textColor}
                bgColor={electionData.election.brandColor?.mainColor}
                onClick={() => setResultsLimit(resultsLimit + 10)}>
                {t('resultsPage.showMoreResults')}
              </ShowMoreButton>
            }
            <Heading>{t('resultsPage.leastSuitableCandidates')}</Heading>
            {[...filteredResults].reverse().slice(0, 4).map((candidate, index) => {
              const candidateGroup = getGroup(candidate.groupId)
              return candidate && (
                <ResultContentCard
                  key={`least-matching-${candidate.id}`}
                  title={`#${index + 1}`}
                  candidateGroup={candidateGroup}
                >
                  <ResultCard score={Number(candidate.score)} candidate={candidate} />
                </ResultContentCard>
              )
            })}
          </>
      </PageContainer>
    ) : <Loader />
  )
}

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${FilterCard} {
    width: 100%;
  }

  ${TopResultCard} {
    margin-top: 100px;
    border-radius: 0 0 8px 8px;

    @media only screen and ${device.mobileL} {
        margin-top: 140px;
    }
  }
`

const ShowMoreButton = styled(Button)`
  margin-top: 20px;
`

const Heading = styled.h2`
  margin-top: 40px;
  color: ${theme.color.grey.darkest};
`
