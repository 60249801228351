import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { diff } from 'deep-diff'
import { Button } from './Button'
import { IQuestion } from '../types/data.types'
import { theme } from '../theme'
import { CheckMarkAtom } from '../atoms/CheckMark.atom'
import { HelperText } from './HelperText'
import { UserAnswer, ArrowDown } from "./AnswerButton"
import { device } from '../theme/deviceSize'
import { getLocalizedText } from '../utils/Text.util'

interface IProps {
  answerToQuestion?: (questionId: string, value: number, multiValue: number[]) => void
  question: IQuestion
  selected?: string | number[]
  userAnswer?: string
  readOnly?: boolean
}

export const AnswersMultiSelect: React.FC<IProps> = (props) => {
  const { answerToQuestion, question, selected, readOnly } = props
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([])
  const [initialAnswers, setInitialAnswers] = useState<number[]>([])
  const [edited, setEdited] = useState(false)
  const { t } = useTranslation()
  let userAnswers: string[]
  if (props.userAnswer) {
    userAnswers = props.userAnswer.toString().includes('-') ? props.userAnswer.split('-') :
      [ props.userAnswer ]
  } else {
    userAnswers = []
  }

  useEffect(() => {
    if (!selected) {
      setSelectedAnswers([])
      setInitialAnswers([])
    } else if (selected.length > 0) {
      const filteredSelected = typeof selected === 'string' ?
        (selected.split('-') || []).filter(s => s).map(s => parseInt(s, 10)) : selected
      setSelectedAnswers(filteredSelected)
      setInitialAnswers(filteredSelected)
    }
  }, [props])

  const onAnswerClick = () => {
    if (answerToQuestion) answerToQuestion(question.id, 1, selectedAnswers)
    setEdited(false)
  }

  const getAnswerIntent = (option: number) => (
      selectedAnswers?.find(selection => selection === option)
        ? 'primary'
        : 'secondary'
    )

  const addOptionToList = (option: number) => {
    const selectionExists = selectedAnswers && selectedAnswers.find((selection: number) => selection === option)
    const updateAnswers = selectionExists ?
      selectedAnswers.filter((selection: number) => selection !== option) :
      selectedAnswers.concat(option)
    setSelectedAnswers(updateAnswers)
    const changed = !!diff(updateAnswers, initialAnswers)
    setEdited(changed)
  }

  const maxOptions = question.maxOptions ? question.maxOptions : 1
  const maxAmountAnswers = () => (selectedAnswers.length >= maxOptions)
  const disabled = !answerToQuestion

  return (
    <>
      <ButtonsContainer saveShown={edited}>
        {question.options && question.options.map(option => (
          <ButtonWrapper key={option.id}>
            {userAnswers.includes(option.value.toString()) && 
              <UserSelectionWrapper>
                <UserAnswer key={`user-answer-${option.id}`}>
                  {t('candidatePage.you')}
                </UserAnswer>
                <ArrowDown />
              </UserSelectionWrapper>
            }
            <Button
              data-cy={`answer-${option.value}`}
              key={`answer-${option.id}`}
              intent={getAnswerIntent(option.value)}
              onClick={() => addOptionToList(option.value)}
              disabled={getAnswerIntent(option.value) !== 'primary' && maxAmountAnswers() || disabled}>
              {getLocalizedText(option)}
            </Button>

          </ButtonWrapper>
        ))}
      </ButtonsContainer>
      {((edited) || (!edited && initialAnswers.length > 0)) && !readOnly &&
          <SaveButton
            data-cy="save-answer"
            key={`save_button_${question.id}`}
            intent="primary"
            onClick={() => onAnswerClick()}>
            <CheckMarkAtom />
            {t('questionsPage.save')}
          </SaveButton>
        }
      <HelperArea>
        <HelperText>
          <b>{t('questionsPage.multiselect')}: </b>
          {t('questionsPage.selectAtMost')} ({maxOptions})
        </HelperText>
      </HelperArea>
    </>
  )
}

const ButtonsContainer = styled.div<{ saveShown: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 60%;
  @media only screen and ${device.tablet} {
      width: 100%;
  }
  flex-direction: column;
  justify-items: center;
  ${Button} {
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    padding: 5px;
    margin: 5px;
    width: 100%;
    min-height: 36px;
    margin: 10px auto;
    word-break: break-word;
  };
  ${UserAnswer} {
    width: 42px;
    z-index: 1;
    padding: 6px 8px;
    position: relative;
    top: 0;

  }
  ${ArrowDown} {
    top: 25px;
  }
  @media only screen and ${device.tablet} {
    ${Button} {
      width: 100%;
    }
  }
  @media only screen and ${device.mobileS} {
    ${Button} {
      padding: 0;
      font-size: 0.7rem;
    }
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HelperArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and ${device.mobileL} {
    margin-bottom: 1.0rem;
  }
`

const SaveButton = styled(Button)`
  margin: 15px auto 80px;
  transition: all 0.2s;
  ${CheckMarkAtom} {
    width: 26px !important;
    height: 26px;
  }
  && {
    background-color: ${theme.color.success.dark};
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: max-content;
    color: ${theme.color.grey.lightest};
  }
`

const UserSelectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
