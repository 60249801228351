import { IWebscaleAnalytics } from './wa'

export const wa: IWebscaleAnalytics = {
  trackPageview: () => {
    window.wa?.trackPageview()
  },
  setConfig: (key, value) => {
    window.wa?.setConfig(key, value)
  }
}
