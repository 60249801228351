import { useState, useEffect, useMemo } from 'react'

interface IHandler {
  (event: { target: any }): void
  (event: { target: any }): void
  (this: Document, ev: MouseEvent): any
  (this: Document, ev: TouchEvent): any
}

/**
 * Click/Touch handler
 * 
 * Add/Remove event listeners based on ref target. Used for dropdown.
 */
export const useClickOutside = (ref: { current: any }, onClickOutside: (e: any) => void, isActive: any) => {
  const [isListening, setListening] = useState(false)
  const handler = (event: { target: any }) => {
    const { current: el } = ref
    if (el && event.target && event.target instanceof HTMLElement && !el.contains(event.target)) {
      onClickOutside(event)
    }
  }
  const addHandlers = (h: IHandler) => {
    document.addEventListener('mousedown', h)
    document.addEventListener('touchstart', h)
  }
  const removeHandlers = (h: IHandler) => {
    document.removeEventListener('mousedown', h)
    document.removeEventListener('touchstart', h)
  }
  useEffect(
    () => {
      if (!isListening && isActive) {
        addHandlers(handler)
        setListening(true)
      } else if (isListening && !isActive) {
        removeHandlers(handler)
        setListening(false)
      }
      return () => {
        removeHandlers(handler)
      }
    },
    [ref, !onClickOutside, isActive])
}


let idCounter = 0
// eslint-disable-next-line no-plusplus
export const useId = () =>  useMemo(() => `vaalikone-input-${idCounter++}`, [])
