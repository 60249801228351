/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import styled from "styled-components"
import { InputAtom, IInputAtomProps } from "../atoms/Input.atom"
import { raise, theme, getBackgroundColor, Intent } from "../theme"

export interface ICheckboxProps extends Omit<IInputAtomProps, "type"> {
  title?: string | JSX.Element
  checked: boolean
  onChange?: (value: any) => void
  className?: string
  htmlFor: string
  intent?: Intent
}

const CheckboxComponent = ({
  className,
  htmlFor,
  title,
  intent,
  ...inputProps
}: ICheckboxProps) => (
  <label className={className} htmlFor={htmlFor}>
    {title}
    <InputAtom type="checkbox" id={htmlFor} {...inputProps} />
    <CheckMark intent={intent} />
  </label>
)

export const Checkbox = styled(CheckboxComponent)`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: ${theme.fontSize.medium};
  color: ${getBackgroundColor("secondary")};
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 auto 80px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  :hover input ~ span {
    ${raise(3)};
  }
  input:checked ~ span {
    :after {
      display: block;
    }
  }
  span:after {
    left: 11px;
    top: 5px;
    width: 5px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
const CheckMark = styled.span<{ intent?: Intent }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: ${({ intent }) =>
    intent ? getBackgroundColor(intent) : theme.color.brandColor.mainColor};
  :after {
    content: "";
    position: absolute;
    display: none;
  }
`
