import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card } from '../elements/Card'
import { useData } from '../stores'
import { theme, raise } from '../theme'
import { RoundImage } from '../elements/Image'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import voteIcon from '../images/vote.svg'
import { Loader } from '../elements/Loader'
import { BrandColorLine } from '../elements/BrandColorLine'
import { toLanguagePath } from '../utils/I18nHelper'
import { parseText } from '../utils/Text.util'
import { Dropdown } from '../elements/DropDown'
import { ClosedIndicator } from '../elements/ClosedIndicator'
import { ButtonLink } from '../elements/Button'

export const HomePage: React.FC = () => {
  const {
    electionData,
    resultStore,
    userAnswer,
    answeringDone,
    areas,
    areaOptions,
    loadCandidates,
    setLoading,
    candidatesLoading,
    electionClosed
    // eslint-disable-next-line @typescript-eslint/no-shadow
  } = useData(({ dataStore, resultStore }) => ({
    resultStore,
    electionData: dataStore.electionData,
    userAnswer: resultStore.userAnswer,
    answeringDone: resultStore.answeringDone,
    areas: dataStore.areas,
    areaOptions: resultStore.areaOptions,
    loadCandidates: dataStore.loadCandidates.bind(dataStore),
    setLoading: dataStore.setLoading.bind(dataStore),
    candidatesLoading: dataStore.loading,
    electionClosed: dataStore.electionClosed
  }))

  const { t, i18n } = useTranslation()

  const hasAnswers = Object.keys(userAnswer).length > 0
  const { image } = electionData.election

  useEffect(() => {
    if(resultStore.areaOptions.selectedArea) {
      changeSelectedArea(resultStore.areaOptions.selectedArea)
    }
  }, [])

  const changeSelectedArea = async (areaId: string) => {
    resultStore.setSelectedArea(areaId)
    setLoading(true)
    await loadCandidates(areaId)
    setLoading(false)
  }

  const getElectionName = () => i18n.language === 'fi' ?
      electionData.election.name :
      (electionData.election[`name_${i18n.language}`] || electionData.election.name)

  const getElectionTitle = () => i18n.language === 'fi' ?
    electionData.election.title :
    (electionData.election[`title_${i18n.language}`] || electionData.election.title)

  const getAreaName = () => {
    if (resultStore.areaOptions.selectedArea) {
      return i18n.language === 'fi' ?
      resultStore.areaOptions.selectedArea :
      (resultStore.areaOptions.selectedArea[`name_${i18n.language}`] || resultStore.areaOptions.selectedArea)
    }
    return undefined
  }

  const showStartButtons = () => {
    if (areaOptions.showAreaDropdown && !areaOptions.presetAreaId && areas.length > 0) {
      return !!areaOptions.selectedArea
    }
    return true
  }

  if (electionClosed) {
    return <ClosedIndicator />
  }

  const areasByName = () => {
    if (i18n.language === 'fi') {
      return areas.map(a => ({ key: a.id, title: a.name })).sort((a, b) => a.title.localeCompare(b.title))
    } 
    return areas.map(a => ({ key: a.id, title: a[`name_${i18n.language}`] })).sort((a, b) => a.title.localeCompare(b.title))
  }

  const getTitle = () => {
    const title = getElectionTitle()
    if (!title) {
      return `${t('homePage.welcomeTo')} ${getElectionName()} ${t('homePage.electionCompass')}`
    }

    return title
  }

  return (
    electionData ?
      <PageContainer>
        <BrandColorLine />
        <Card>
          <Content>
            {electionData.election && electionData.election.name ?
              <>
                <Image image={image} placeholder={voteIcon} alt="Vaalikone ikoni" size="100px" />
                <Welcome>{getTitle()}</Welcome>
                <Description dangerouslySetInnerHTML={{ __html: parseText(electionData.election.description || '') }} />
                <ButtonGroup>
                  {areaOptions.showAreaDropdown && areas.length > 0 &&
                    <Dropdown
                      withSearch
                      options={areasByName()}
                      placeholder={t('homePage.selectArea')}
                      selected={getAreaName()}
                      onSelect={key => changeSelectedArea(String(key))}
                    />
                  }

                  {hasAnswers ?
                    <ButtonLink data-cy="restart-answering" to={toLanguagePath('/kysymykset/1', i18n)}
                      onClick={() => resultStore.clearResults()}
                      brandcolors={electionData.election.brandColor}>
                      {t('homePage.restart')}
                    </ButtonLink>
                    :
                    <ButtonLink disabled={!showStartButtons()} data-cy="start-answering" to={showStartButtons() ? toLanguagePath('/kysymykset/1', i18n) : ''}
                      brandcolors={electionData.election.brandColor}>
                      {t('homePage.start')}
                    </ButtonLink>
                  }

                  {hasAnswers &&
                    <>
                      {answeringDone &&
                        <TextLink data-cy="results-page" to={toLanguagePath('/tulokset', i18n)}>
                          {t('homePage.results')}
                        </TextLink>
                      }
                      <TextLink data-cy="browse-answers" to={toLanguagePath('/kysymykset/1', i18n)}>
                        {t('homePage.browseAnswers')}
                      </TextLink>
                    </>
                  }
                  <TextLink disabled={candidatesLoading} data-cy="browse-candidates" to={candidatesLoading ? '' : toLanguagePath('/ehdokkaat', i18n)}>
                    {t('homePage.browseCandidates')}
                  </TextLink>
                </ButtonGroup>
              </>
              :
              <Loader />
            }
          </Content>
        </Card>
      </PageContainer>
      : <p>{t('homePage.dataNotFound')}</p>
  )
}
const Welcome = styled.h1`
  font-size: ${theme.fontSize.large};
  text-align: center;
`
const Image = styled(RoundImage)`
  margin-bottom: 20px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  padding: 40px 20px 20px 20px;
  z-index: 1;
  align-items: center;
`

const TextLink = styled(NavLink)<({ disabled?: boolean })>`
  text-decoration: underline  !important;
  margin-top: 20px;
  color: ${theme.color.grey.dark} !important;
  font-size: ${theme.fontSize.small} !important;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};

  &:focus {
    box-shadow: ${raise(2)};
    transform: scale(1.1);
    outline: 0;
    transition: transform 260ms ease-out;
  }
`
const Description = styled.p`
  text-align: center;
  flex-grow: 1;
  color: ${theme.color.black};
  a {
    text-decoration: underline !important;
    color: ${theme.color.success.default} !important;
  }
`
const PageContainer = styled.div`
  ${Card} {
   overflow: hidden;
   padding: 0px;
   min-height: 400px;
   display: flex;
   flex-direction: column;
   border-radius: 4px;
   &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 0;
    top: -200px;
    left: 0;
    right: 0;
    bottom: 90px;
    background: #f6f9fc;
    transform: skewY(-8deg);
   }
  }
`

const ButtonGroup = styled.div`
  margin: 60px 0px 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button, a {
    margin: 5px;
  }
  ${Dropdown} {
    margin-bottom: 60px;
  }
`
