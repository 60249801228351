import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IDetailedCandidate } from '../types/data.types'
import { useData } from '../stores'
import { CandidateDetailCard } from '../cards/CandidateDetailCard'
import { CandidateAnswerCard } from '../cards/CandiateAnswerCard'
import { Loader } from '../elements/Loader'
import { toLanguagePath } from '../utils/I18nHelper'
import { theme } from '../theme'

export const {BUCKET_URL} = process.env

interface IMatchParams {
  candidateId: string
}

export const CandidatePage: React.FC = () => {
  const { groups, areas, bgQuestions, questions, getDetailedCandidate, answers, circles, alliances, hideQuestionNumbering } = useData(
    ({ dataStore, resultStore }) => ({
      questions: dataStore.electionData.questions,
      bgQuestions: dataStore.electionData.bgQuestions,
      groups: dataStore.electionData.groups,
      answers: resultStore.userAnswer,
      areas: dataStore.areas,
      getDetailedCandidate: dataStore.getDetailedCandidate.bind(dataStore),
      alliances: dataStore.electionData.alliances,
      circles: dataStore.electionData.circles,
      hideQuestionNumbering: dataStore.electionData.election.hideQuestionNumbering
    })
  )
  const [loading, setLoading] = useState(true)
  const [candidate, setCandidate] = useState<IDetailedCandidate | undefined>(undefined)
  const { t, i18n } = useTranslation()
  const { candidateId } = useParams<IMatchParams>()

  useEffect(() => {
    getDetailedCandidate(candidateId)
      .then(c => setCandidate(c))
      .finally(() => setLoading(false))
  }, [candidateId])

  const group = groups.find(g => g.id === candidate?.groupId)
  const alliance = alliances.find(a => a.id === candidate?.electoralAllianceId)
  const circle = circles.find(c => c.id === candidate?.electoralCircleId)
  const area = areas.find(a => a.id === candidate?.areaId)

  if (loading) {
    return <Loader />
  }

  return candidate ? (
    <>
      <ResultWrapper>
        <Title>{t('candidatePage.candidateInfo')}</Title>
        <CandidateDetailCard
          candidate={candidate}
          group={group}
          area={area}
          bgQuestions={bgQuestions}
          alliance={alliance}
          circle={circle}
        />
      </ResultWrapper>
      <ResultWrapper>
        <Title>{t('candidatePage.candidateAnswers')}</Title>
        {questions.map((q, i) => (
          <CandidateAnswerCard
            key={q.id}
            question={q}
            candidate={candidate}
            index={i}
            userAnswer={answers[q.id]}
            total={questions.length}
            hideQuestionNumbering={hideQuestionNumbering}
          />
        ))}
      </ResultWrapper>
    </>
  ) : (
    <Redirect to={toLanguagePath('/', i18n)} />
  )
}

const Title = styled.h3`
  color: ${theme.color.grey.dark};
  margin: 10px 0;
  font-size: 24px;
`

const ResultWrapper = styled.div`
  border-radius: 8px;
  background-color: #f6f9fc;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;

  ${CandidateDetailCard} {
    margin-top: 80px;
  }

  ${CandidateAnswerCard} {
    margin-bottom: 10px;
  }
`
