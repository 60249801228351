/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from 'styled-components'
import { FiChevronDown } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { raise, theme } from '../theme'
import { IGroup } from '../types/data.types'
import { IGroupResult } from '../types/result.types'
import { device } from '../theme/deviceSize'
import { ResultContentCard } from '../elements/ResultContentCard'
import { GroupTag } from '../atoms/GroupTag.atom'

interface IProps {
  groupResults: IGroupResult[]
  groups: IGroup[]
}

const MIN_AMOUNT_SHOWN = 5

const GroupSimilaritiesCardComponent: React.FC<IProps> = ({ groupResults, groups }) => {
  const { t } = useTranslation()
  const [expandGroups, setExpandGroups] = useState(false)

  const groupSimilarity = groupResults.map((g) => {
    const group = groups.filter(g1 => g1.id === g.groupId)
    return {
      score: g.score,
      ...group[0]
    }
  })

  if (!groupSimilarity || groupSimilarity.length === 0) {
    return null
  }

  return (
    <ResultContentCard title={`${t('resultsPage.partyComparison')}`} infoText={t('resultsPage.partyResultInfo')}>
      <GroupsResult
        isOpen={expandGroups}
        minAmount={groupSimilarity.slice(0, MIN_AMOUNT_SHOWN).length}
        maxAmount={groupSimilarity.length}
      >
        {groupSimilarity.map((group, index) =>
          <GroupSimilarity key={group.id}>
            <GroupName><span>#{index + 1}</span>{group.name}</GroupName>
            <GroupItemWrapper>
              <GroupTag size="s" title={group.abbreviation} color={group.color || '#ffffff'}/>
              <PercentageIndicator percentage={Math.round(100 * group.score)}>
                <div />
                <p>{Math.round(100 * group.score)} %</p>
              </PercentageIndicator>
            </GroupItemWrapper>
          </GroupSimilarity>
        )}
        {groupSimilarity.length > MIN_AMOUNT_SHOWN &&
          <ExpandGroups isOpen={expandGroups} onClick={() => setExpandGroups(!expandGroups)}><FiChevronDown /></ExpandGroups>
        }
      </GroupsResult>
    </ResultContentCard>
  )
}

export const GroupSimilaritiesCard = styled(GroupSimilaritiesCardComponent)``

const GroupsResult = styled.div<({ isOpen: boolean, minAmount: number, maxAmount: number }) >`
  width: 100%;
  height: ${({ isOpen, minAmount, maxAmount }) => maxAmount < 6 ?
    `${(maxAmount * 55)}px` : (isOpen ? `${(maxAmount * 55) + 40}px` : `${(minAmount * 55) + 40}px`)};
  transition: height 0.4s ease;
  overflow: hidden;
  background-color: ${theme.color.white};
  ${raise(2)};
  border-radius: 8px;
  position: relative;

  @media only screen and ${device.mobileM} {
    height: ${({ isOpen, minAmount, maxAmount }) => maxAmount < 6 ?
    `${(maxAmount * 85)}px` : (isOpen ? `${(maxAmount * 85) + 40}px` : `${(minAmount * 85) + 40}px`)};
`

const GroupName = styled.p`
  margin: 0 0 0 5px !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 100% !important;
  display: block !important;
  @media only screen and ${device.mobileS} {
    font-size: 0.8rem;
  }
`

const GroupItemWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${GroupTag} {
    margin-right: 10px;
  }
`

const PercentageIndicator = styled.div<({ percentage: number }) >`
  margin-right: 20px;
  height: 25px;
  width: 100%;
  background-color: ${theme.color.grey.light};
  border-radius: 2px;
  position: relative;
  display: flex;
  align-items: center;
  p {
    position: absolute;
    z-index: 1;
    margin: 0 0 0 3px;
    color: white;
  }
  div {
    width: ${props => props.percentage}%;
    border-radius: 2px;
    height: 25px;
    background-color: ${theme.color.success.default};
  }
`

const ExpandGroups = styled.div<({ isOpen: boolean }) >`
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
    transform: ${({ isOpen }) => isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.25s ease-out;
  }
  background-color: ${theme.color.white};
`

const GroupSimilarity = styled.div`
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  font-size: ${theme.fontSize.medium};
  font-weight: bold;
  span {
    margin: 0 5px;
  }
  @media only screen and ${device.mobileM} {
    flex-wrap: wrap;
    height: 85px;
    padding: 10px;
    p {
      margin: 12px 0 5px 0;
    }
  }
`
