import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../theme'
import { ICandidate } from '../types/data.types'
import { LinkCard } from '../elements/LinkCard'
import { RectangularImage } from '../elements/Image'
import { toLanguagePath } from '../utils/I18nHelper'
import { device } from '../theme/deviceSize'

interface IProps {
  className?: string
  candidate: ICandidate
  score: number
}
const ResultCardElement: React.FC<IProps> = ({ candidate, className, score }) => {
  const { i18n } = useTranslation()
  const candNumberLength = candidate.electionNumber?.length
  return (
    <LinkCard className={className} to={toLanguagePath(`/ehdokkaat/${candidate.id}`, i18n)}>
      <Score>{Math.round(score * 100)}%</Score>
      <Name>{candidate.firstName} {candidate.lastName}</Name>
      <ImageContainer>
        <RectangularImage image={candidate.image} placeholder={`${process.env.IMAGES_PREFIX}/man.png`} alt={`Ehdokas ${candidate.firstName} ${candidate.lastName}`} />
        {candidate.electionNumber &&
          <CandidateNr numberLength={candNumberLength}>{candidate.electionNumber}</CandidateNr>}
      </ImageContainer>
    </LinkCard>
  )
}

const Score = styled.div`
  flex-shrink: 0;
  color: ${theme.color.black};
  font-weight: 600;
  font-size: 32px;
  font-family: ${theme.font.number};
  padding: 10px;
  border-right: 1px solid #eee;
`

export const ResultCard = styled(ResultCardElement)`
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 80px;

  ${LinkCard} {
    margin-bottom: 0;
  }

  ${RectangularImage} {
    flex-shrink: 0;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-self: center;
    width: 80px;
    height: 80px;
  }
  
   @media only screen and ${device.mobileL} {
    flex-direction: column;
    padding: 10px 0 25px 0;
    h3 {
      text-align: center;
    }
    ${RectangularImage} {
      border-radius: 80px;
    }
    ${Score} {
      border-right: 0;
    }
  }
`

const Name = styled.h3`
  flex-grow: 1;
  color: ${theme.color.black};
  font-weight: 600;
  font-size: ${theme.fontSize.large};
  text-align: left;
  margin: 0 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;

  @media only screen and ${device.mobileL} {
    margin: 5px 10px;
  }
`

const ImageContainer = styled.div`
  position: relative;
`

const CandidateNr = styled.div<({ numberLength?: number }) >`
  position: absolute;
  bottom: -13px;
  right: -13px;
  background-color: ${theme.color.grey.light};
  font-weight: bold;
  ${({ numberLength }) => numberLength && numberLength > 3 ? 'font-size: 14px;' : 'font-size: 16px;'};
  padding: 3px;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;

  @media only screen and ${device.mobileL} {
    right: ${({ numberLength }) => numberLength && numberLength > 3 ? `calc(-13px - ${numberLength * 3}px)` : '-13px'};
  }
`
