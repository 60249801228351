import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card } from '../elements/Card'
import { theme } from '../theme'
import { ProgressBar } from '../elements/ProgressBar'
import { useData } from '../stores'
import { AnswerOneToFive } from '../elements/AnswersOneToFive'
import { TopContendersCard } from '../cards/TopContendersCard'
import { AnswersMultiSelect } from '../elements/AnswersMultiSelect'
import { IQuestion } from '../types/data.types'
import { Loader } from '../elements/Loader'
import { toLanguagePath } from '../utils/I18nHelper'
import { device } from '../theme/deviceSize'
import { getLocalizedText, getLocalizedName } from '../utils/Text.util'
import { Modal } from '../elements/Modal'
import { AnswersOneToTen } from '../elements/AnswersOneToTen'
import { Checkbox } from '../elements/Checkbox'
import { AnswersYesNo } from '../elements/AnswersYesNo'
import { PriorityList } from '../elements/PriorityList/PriorityList'

interface IMatchParams {
  questionNumber: string
}

export const QuestionsPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { resultStore, electionData, userAnswer, results, candidates, loading } = useData(({ dataStore, resultStore }) => ({
    resultStore,
    candidates: dataStore.candidates,
    results: resultStore.results,
    userAnswer: resultStore.userAnswer,
    electionData: dataStore.electionData,
    loading: dataStore.loading
  }))

  const [showInfo, setShowInfo] = useState<string | undefined>(undefined)
  const history = useHistory()
  const { questionNumber } = useParams<IMatchParams>()
  const questionCount = electionData.questions.length || 0
  const { t, i18n } = useTranslation()

  const questionIndex = (parseInt(questionNumber!, 10) - 1)
  const question = useMemo(() => electionData.questions[`${questionIndex}`], [`${questionIndex}`])
    || electionData.questions[`${questionIndex}`]

  const selected = useMemo(() => resultStore.getUserAnswer(question?.id), [question?.id, userAnswer, questionNumber])

  const nextUrl = (questionIndex + 1) < questionCount ? `/kysymykset/${questionIndex + 2}` : '/tulokset'

  const moveToNext = () => {
    history.push(toLanguagePath(nextUrl, i18n))
  }

  useEffect(() => {
    if (Object.keys(userAnswer).length > 1) {
      resultStore.getTop10Results(resultStore.answerToken)
    }
  }, [questionIndex])

  const answerToQuestion = (questionId: string, answer: number, multiAnswer?: number[]) => {
    resultStore.setAnswerToQuestion(questionId, answer, multiAnswer)
    setTimeout(moveToNext, 600)
  }

  const parseText = (text: string) => {
    // Match all {{ korostus: **** }}
    const bolded = text.match(/\{\{ *korostus:.+?\}\}/g)
    // Match all {{ teksti: ****, linkki: **** }}
    const links = text.match(/\{\{ *teksti:.+?linkki:.+?\}\}/g)
    let parsedText = text
    if (links && links.length > 0) {
      links.forEach((link: string) => {
        // Get text of link
        const linkText = link.split('teksti:')[1].split('linkki:')[0].trim().replace(/,$/, '')
        // Get link address
        const linkAddress = link.split('linkki:')[1].replace(/\}\}$/, '').trim()
        parsedText = parsedText.replace(link, `<a href=${linkAddress} target=_blank>${linkText} </a>`)
      })
    }

    if (bolded && bolded.length > 0) {

      bolded.forEach((bold: string) => {
        // Get text of link
        const boldText = bold.split('korostus:')[1].trim().replace(/\}\}$/, ' ')
        parsedText = parsedText.replace(bold, `<b>${boldText}</b>`)
      })
    }
    return parsedText
  }

  const nl2paragraphs = (text: string) => text && text.split(/[\n]+/).map((p, i) => `<p>${p}</p>`).join('')

  const getAnswerOptions = (q: IQuestion) => {
    const { type } = q
    const prioSelected = type === 'PRIORITY_LIST' && typeof selected === 'string' ?
    (selected.split('-') || []).filter(s => s).map(s => parseInt(s, 10)) : selected
    switch (type) {
      case 'ONE_TO_FIVE':
        return <AnswerOneToFive question={q} value={selected as number} answerToQuestion={answerToQuestion} />
      case 'YES_NO':
        return question.appearance === 'CHECKBOX' ?
         <Checkbox
          htmlFor={`yes_no_question_${q.id}`}
          checked={(selected as number) === 1}
          onChange={() => answerToQuestion(q.id, !selected || selected === 2 ? 1 : 2)}
          intent={selected === 1 ? "primary" : 'secondary'}/>
        : <AnswersYesNo question={q} value={selected as number} answerToQuestion={answerToQuestion} />
      case 'MULTISELECT':
        return <AnswersMultiSelect question={q} selected={selected as string} answerToQuestion={answerToQuestion} />
      case 'ONE_TO_TEN':
        return <AnswersOneToTen question={q} value={selected as number} answerToQuestion={answerToQuestion} />
      case 'PRIORITY_LIST':
        return <PriorityList options={q.options!} questionId={q.id} selected={prioSelected as number[]} onAnswer={(val) => answerToQuestion(q.id, 1, val)} />

      default:
        return null
    }
  }

  const categoryName = (categoryId: string) => getLocalizedName(electionData.categories.find(c => c.id === categoryId))

  if (loading) {
    return <Loader />
  }

  return (
    (question ?
      <PageContainer>
        <Card>
          <ProgressBar total={questionCount} current={questionIndex} />
          <CardContainer>
            {question.categoryId && <QuestionCategory>{categoryName(question.categoryId)}</QuestionCategory>}
            {!electionData.election.hideQuestionNumbering && <QuestionNumber>
              <CurrentNumber>{questionNumber}</CurrentNumber>
              <NumberSeparator>/</NumberSeparator>
              <TotalCount>{questionCount}</TotalCount>
            </QuestionNumber>}
            <QuestionText
              dangerouslySetInnerHTML={{ __html: parseText(getLocalizedText(question)) }}
            />
            {question.explanation &&
            <>
              <ExplanationLink href="#" onClick={(e) => {
                e.preventDefault()
                setShowInfo(nl2paragraphs(parseText(getLocalizedText(question.explanation))))
              }}>{t('questionsPage.info')}</ExplanationLink>
              {showInfo && <Modal dismiss={() => setShowInfo(undefined)} content={showInfo} />}
            </>
            }
            {getAnswerOptions(question)}
            <ActionButtons>
              {questionIndex > 0 ?
              <Link to={toLanguagePath(`/kysymykset/${questionIndex}`, i18n)}>{t('questionsPage.previous')}</Link> :
              <span />}
              <Link to={toLanguagePath(nextUrl, i18n)}>{t('questionsPage.skip')}</Link>
            </ActionButtons>
          </CardContainer>
        </Card>
        {questionIndex > 1 && results?.length > 0
          && <TopContendersCard candidates={candidates} scores={results} />}
      </PageContainer>
      : <Error>
          <p>{t('questionsPage.pageNotFound')}</p> <Link to={toLanguagePath('/', i18n)}>{t('questionsPage.backToStart')}</Link>
        </Error>)
  )
}

const PageContainer = styled.div`
  ${Card} {
    padding: 0;
    border-radius: 0 0 4px 4px;
  }

  ${ProgressBar} {
    margin-bottom: 60px;
  }

  ${TopContendersCard} {
    margin-top: 10px;
  }
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  @media only screen and ${device.mobileS} {
    padding: 10px;
  }
`

const QuestionNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 20px;
`
const CurrentNumber = styled.span`
  font-size: ${theme.fontSize.xlarge};
  font-family: ${theme.font.number};
  color: ${theme.color.black};
`
const NumberSeparator = styled.span`
  font-size: ${theme.fontSize.mediumLarge};
  font-family: ${theme.font.number};
  color: ${theme.color.grey.default};
  padding: 0 4px;
`
const TotalCount = styled.span`
  font-family: ${theme.font.number};
  font-size: ${theme.fontSize.mediumLarge};
  color: ${theme.color.grey.default};
  padding-bottom: 4px;
`
const QuestionText = styled.div`
  text-align: center;
  font-size: ${theme.fontSize.mediumLarge};
  padding-bottom: 20px;
  min-height: 140px;
  line-height: 1.8rem;

  a {
    text-decoration: underline !important;
    color: ${theme.color.success.default} !important;
  }

  @media only screen and ${device.mobileS} {
    font-size: 0.9rem;
    line-height: 22px;
  }
`
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a, a:visited {
    color: ${theme.color.grey.default} !important;
    &:hover {
      color: ${theme.color.black} !important;
    }
  }
`

const Error = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: ${theme.color.black};
    font-weight: bold;
  }
  a, a:visited {

  }
`

const QuestionCategory = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: bold;
  color: ${theme.color.grey.default};
`

const ExplanationLink = styled.a`
  padding: 5px 10px;
  font-size: 12px;
  background-color: #eee;
  border-radius: 3px;
  text-decoration: underline;
  margin-bottom: 10px;
`
