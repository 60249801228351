import styled from 'styled-components'
import React, { useState } from 'react'
import FlipMove from 'react-flip-move'
import { FiEye,  FiEyeOff } from 'react-icons/fi'
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { ICandidate } from '../types/data.types'
import { RectangularImage } from '../elements/Image'
import { IResult } from '../types/result.types'
import { theme } from '../theme'
import { device } from '../theme/deviceSize'
import { toLanguagePath } from "../utils/I18nHelper"

interface IProps {
  className?: string
  candidates: ICandidate[]
  scores: IResult[]
}
const TopContendersCardElement: React.FC<IProps> = ({ candidates, className, scores }) => {
  const [hidden, setHidden] = useState(false)
  const { i18n } = useTranslation()
  const history = useHistory()

  return (
    <div className={className}>
      <TopContenders>
        <FlipMove typeName={null}>
          {!hidden ? scores.slice(0, 5).map(({ candidateId, score }) => {
            const candidate = candidates.find(c => c.id === candidateId)
            return candidate ? (
              <Contender key={candidateId} title={`${candidate.firstName} ${candidate.lastName}`} onClick={() => history.push(toLanguagePath(`/ehdokkaat/${candidate.id}`, i18n))}>
                <RectangularImage
                  image={candidate.image}
                  placeholder={`${process.env.IMAGES_PREFIX}/man.png`}
                  alt={`Ehdokas ${candidate.firstName} ${candidate.lastName}`}
                />
                <Score>{Math.round(score * 100)}%</Score>
              </Contender>
            ) : undefined
          }) : [1, 2, 3, 4, 5].map((n) => <Contender key={n}>
                <RectangularImage
                  image={`${process.env.IMAGES_PREFIX}/man.png`}
                  placeholder={`${process.env.IMAGES_PREFIX}/man.png`}
                  alt="Hidden candidate"
                />
                <NoScore />
              </Contender>)}
        </FlipMove>
      </TopContenders>
      <ToggleContenders>
        <button onClick={() => setHidden(!hidden)} title={`${hidden ? 'Näytä' : 'Piilota'} top 5 ehdokkaat`} type="button">
          {hidden ? <FiEyeOff size={20} /> : <FiEye size={20} />}
        </button>
      </ToggleContenders>
    </div>
  )
}

const Contender = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
  cursor: pointer;
  @media only screen and ${device.mobileM} {
    margin: 5px;
  }
`
const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${ theme.font.number};
  font-size: ${theme.fontSize.small};
  color: ${theme.color.grey.default};
  font-weight: 600;
  padding: 4px 0;
`

const NoScore = styled.div`
  height: 25px;
`

export const TopContendersCard = styled(TopContendersCardElement)`
  display: flex;
  margin: 10px 0;
`

const TopContenders = styled.div`
  position: relative;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${theme.color.grey.lightest};
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  ${RectangularImage} {
    flex-shrink: 0;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-self: center;
    width: 60px;
    height: 60px;
  }

  @media only screen and ${device.mobileM} {
    flex-wrap: wrap;
    ${RectangularImage} {
      width: 50px;
      height: 50px;
    }
  }
`

const ToggleContenders = styled.div`
  align-items: center;
  display: flex;
  padding: 0px 10px 0px 8px;
  border-color: ${theme.color.grey.lightest} !important;
  border-right: 2px solid;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    height: 100%;
  }
  svg {
    color: ${theme.color.grey.default};
  }
`
