import * as React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { device } from '../theme/deviceSize'

interface IProps {
  percentage: number
  title?: string
  className?: string
}

const WIDTH = 5
const ResultDialElement: React.FC<IProps> = (props) =>  {
  const original = Math.round(props.percentage * 100)
  const percentage =  Math.round((props.percentage * 100) * 0.75)
  return (
    <div className={props.className}>
      <Dial>
        <svg width="100%" height="100%" viewBox="0 0 42 42" aria-label={`${percentage}%`} role="img">
          <circle className="donut-segment" cx="21" cy="21" r="16.4" fill="transparent" stroke={theme.color.orange.dark}
                  strokeWidth={WIDTH} strokeDasharray={`${percentage - 2 } ${100 - percentage + 2}`} strokeDashoffset="59.5"/>
          <circle className="donut-segment" cx="21" cy="21" r="15.4" fill="transparent" stroke={theme.color.orange.light}
                  strokeWidth={WIDTH} strokeDasharray={`${percentage} ${100 - percentage}`} strokeDashoffset="62"/>
          <g className="chart-text">
            <text x="50%" y="50%" className="chart-label">
              { original }%
            </text>
          </g>
        </svg>
      </Dial>
      <p>{props.title}</p>
    </div>
  )
}

const Dial = styled.figure`
  margin: 0;
  height: 80px;
  width: 80px;
  .chart-text {
    fill: ${theme.color.black};
    transform: translateY(0.2em);
    
    .chart-label {
      font-size: 8px;
      text-anchor: middle;
      font-weight: bold;
      font-family: ${theme.font.number};
    }
  }
  @media only screen and (max-width: ${theme.breakPoints.md}px) {
    width: 60px;
    height: 60px;
  } 
`

export const ResultDial = styled(ResultDialElement)`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
     font-size: ${theme.fontSize.small};
     font-weight: bold;
     text-align: center;
     width: 100%;
     overflow-x: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     margin: -14px 0 0 0;
    }
  }

  @media only screen and ${device.mobileM} {
    width: 100px;
  }

  @media only screen and ${device.mobileM} {
    width: 80px;
  }
`
