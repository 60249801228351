/* eslint-disable no-bitwise */
export const hexColorDark = (color: string) => {
  const colorInt = parseInt(color.substr(1), 16)

  // Calculate red, green and blue values with Bitwise operators
  const r = (colorInt >> 16) & 0xFF
  const g = (colorInt >> 8) & 0xFF
  const b = colorInt & 0xFF

  // Color visibility https://www.w3.org/TR/AERT/#color-contrast
  const colorVisibility =
    (0.299 * r) +
    (0.587 * g) +
    (0.114 * b)
    / 1000

  return colorVisibility < 175
}
