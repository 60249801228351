import React from 'react'
import { theme } from '../theme'

interface IProps {
  color?: string
  height?: number
}

export const LoaderIconAtom = (props: IProps) => (
  <svg
    viewBox="0 0 32 32"
    width={`${props.height || '24'}px`}
    height={`${props.height || '24'}px`}
    fill={props.color || `${theme.color.grey.dark}`}
  >
    <path
      opacity=".25"
      d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
    />
    <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 16 16"
        to="360 16 16"
        dur="0.8s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)
