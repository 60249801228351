import { action, observable } from 'mobx'
import * as dataApi from '../api/data.api'
import { ICandidate, IElectionData, IArea } from '../types/data.types'

export class DataStore {
  readonly electionId: string

  @observable candidates: ICandidate[] = []

  @observable electionData: IElectionData = {
    groups: [],
    questions: [],
    bgQuestions: [],
    categories: [],
    election: {},
    alliances: [],
    circles: []
  }

  @observable areas: IArea[] = []

  @observable answerCount = 0

  @observable loading = true

  @observable electionClosed = false

  constructor(electionId: string) {
    this.electionId = electionId
  }

  @action async loadElectionData() {
    try {
      const result = await dataApi.getElectionData(this.electionId)
      if (result) {
        this.electionData = result
        return result
      }
    } catch (e) {
      if (e.status === 403) {
        this.electionClosed = true
      }
    }
    return null
  }

  @action async loadCandidates(areaId?: string) {
    const result = await dataApi.getCandidates(this.electionId, areaId)
    if (result) {
      this.candidates = result
    }
  }

  async getDetailedCandidate(candidateId: string) {
    return dataApi.getCandidateById(this.electionId, candidateId)
  }

  @action async loadAreas() {
    const result = await dataApi.getAreas(this.electionId)
    if (result) {
      this.areas = result
    }
  }

  @action async setLoading(value: boolean) {
    this.loading = value
  }
}
