import React from "react"
import styled from "styled-components"
import { theme } from "../theme"

import { IDetailedCandidate, IQuestion } from "../types/data.types"
import { Card } from "../elements/Card"
import { AnswerOneToFive } from "../elements/AnswersOneToFive"
import { AnswersYesNo } from "../elements/AnswersYesNo"
import { AnswersMultiSelect } from "../elements/AnswersMultiSelect"
import { parseText, getLocalizedText } from "../utils/Text.util"
import { device } from "../theme/deviceSize"
import { AnswersOneToTen } from "../elements/AnswersOneToTen"
import { Checkbox } from "../elements/Checkbox"
import { PriorityList } from "../elements/PriorityList/PriorityList"

interface IProps {
  className?: string
  candidate: IDetailedCandidate
  question: IQuestion
  index: number
  total: number
  userAnswer?: number | string
  hideQuestionNumbering?: boolean
}

const CandidateAnswerCardElement: React.FC<IProps> = ({
  candidate,
  className,
  question,
  index,
  total,
  userAnswer,
  hideQuestionNumbering,
}) => {
  const answer = candidate.answers[question.id]

  const getAnswerOptions = (q: IQuestion) => {
    const { type } = q
    switch (type) {
      case "ONE_TO_FIVE":
        return (
          <AnswerOneToFive
            question={q}
            value={answer?.value as number}
            userAnswer={userAnswer as number}
          />
        )
      case "ONE_TO_TEN":
        return (
          <AnswersOneToTen
            question={question}
            value={answer?.value as number}
            userAnswer={userAnswer as number}
          />
        )
      case "YES_NO":
        return q.appearance === "CHECKBOX" ? (
          <Checkbox
            checked={answer?.value === 1}
            disabled
            htmlFor={`yes_no_question_${q.id}`}
            intent={answer?.value === 1 ? "primary" : "secondary"}
          />
        ) : (
          <AnswersYesNo
            question={q}
            value={answer?.value as number}
            userAnswer={userAnswer as number}
          />
        )
      case "MULTISELECT":
        return (
          <AnswersMultiSelect
            readOnly
            question={q}
            selected={answer?.multiValue as number[]}
            userAnswer={userAnswer as string}
          />
        )
      case "PRIORITY_LIST":
        return (
          q.options && (
            <PriorityList
              readonly
              questionId={q.id}
              selected={answer?.multiValue as number[]}
              options={q.options}
              userAnswer={userAnswer as string}
              showSpeechBubbles
            />
          )
        )
      default:
        return null
    }
  }

  return (
    <Card className={className}>
      {!hideQuestionNumbering && (
        <QuestionNumber>
          <CurrentNumber>{index + 1}</CurrentNumber>
          <NumberSeparator>/</NumberSeparator>
          <TotalCount>{total}</TotalCount>
        </QuestionNumber>
      )}
      <QuestionText
        dangerouslySetInnerHTML={{
          __html: parseText(getLocalizedText(question)),
        }}
      />
      {getAnswerOptions(question)}
      {answer?.reasoning && <Explanation>{answer.reasoning}</Explanation>}
    </Card>
  )
}

const QuestionNumber = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`
const CurrentNumber = styled.span`
  font-size: ${theme.fontSize.xlarge};
  font-family: ${theme.font.number};
  color: ${theme.color.black};
`
const NumberSeparator = styled.span`
  font-size: ${theme.fontSize.mediumLarge};
  font-family: ${theme.font.number};
  color: ${theme.color.grey.default};
  padding: 0 4px;
`
const TotalCount = styled.span`
  font-family: ${theme.font.number};
  font-size: ${theme.fontSize.mediumLarge};
  color: ${theme.color.grey.default};
  padding-bottom: 4px;
`
const QuestionText = styled.div`
  text-align: center;
  font-size: ${theme.fontSize.mediumLarge};
  margin: 30px 0 60px 0;
  padding: 0;
  min-height: 50px;
  a {
    text-decoration: underline !important;
    color: ${theme.color.success.default} !important;
  }

  @media only screen and ${device.mobileS} {
    font-size: 0.9rem;
    line-height: 22px;
  }
`

const Explanation = styled.div`
  display: block;
  min-height: 80px;
  border-radius: 8px;
  border: 1px solid ${theme.color.grey.light};
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  font-size: ${theme.fontSize.small};
  width: 100%;
  padding: 10px;
  white-space: pre-wrap;
`

export const CandidateAnswerCard = styled(CandidateAnswerCardElement)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${AnswerOneToFive} {
    margin: 0 auto 20px;
  }
`
