import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { theme, raise  } from '../theme'

interface IProps {
  className?: string
  title?: string | React.ReactNode
  onClick?: () => void
}

const CardBase: FunctionComponent<IProps> = props => (
  <CardBaseElement className={props.className} onClick={props.onClick && props.onClick}>
    {props.title && <CardTitle>{props.title}</CardTitle>}
    {props.children}
  </CardBaseElement>
)

const CardBaseElement = styled.div``

const CardTitle = styled.h1`
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  margin: 0px 0px 20px;
  color: ${theme.color.black};
  font-size: 24px;
`

export const Card = styled(CardBase)`
  padding: 24px;
  position: relative;
  background-color: #fff;
  border-radius: 0px;
  min-height: 100px;
  ${raise(1)};
  width: 100%;
`
