import React from 'react'

export interface IInputAtomProps {
  disabled?: boolean
  className?: string
  value?: string | number
  placeHolder?: string
  required?: boolean
  onKeyPress?: (event: any) => void
  onChange?: (value: any) => void
  type: 'email' | 'password' | 'text' | 'number' | 'checkbox'
  'data-cy'?: string
  checked?: boolean
  id?: string
}

export const InputAtom: React.FunctionComponent<IInputAtomProps> = props => (
  <input
    data-cy={props['data-cy']}
    type={props.type}
    className={props.className}
    value={props.value}
    placeholder={props.placeHolder}
    disabled={props.disabled}
    required={props.required}
    onChange={(event: any) => !props.disabled && props.onChange && props.onChange(event.target.value)}
    onKeyPress={(event: any) => props.onKeyPress && props.onKeyPress(event)}
    checked={props.checked}
    id={props.id}
  />
)
