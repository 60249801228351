import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { useTranslation } from "react-i18next"
import { FiCheck, FiHelpCircle } from "react-icons/fi"
import { PriorityListOption } from "./PriorityListOption"
import { theme } from "../../theme"
import { IQuestionsOption } from "../../types/data.types"
import { Button } from "../Button"
import { CheckMarkAtom } from "../../atoms/CheckMark.atom"
import { Tooltip, TooltipContent } from "../Tooltip"
import { getLocalizedText } from "../../utils/Text.util"

export type PriorityListProps = {
  options: IQuestionsOption[]
  explanationText?: string
  title?: React.ReactNode
  disabled?: boolean
  selected: number[]
  onAnswer?: (val: number[]) => void
  questionId: string
  overriderColors?: {
    doneBg?: string
    infoBg?: string
  }
  height?: string
  width?: string
  readonly?: boolean
  className?: string
  userAnswer?: string
  showSpeechBubbles?: boolean
}

const MAX_WIDTH = "311px"

const PriorityListComponent = ({
  options,
  title,
  explanationText,
  disabled,
  onAnswer,
  selected,
  questionId,
  overriderColors,
  className,
  height,
  readonly = false,
  width = MAX_WIDTH,
  userAnswer,
  showSpeechBubbles,
}: PriorityListProps) => {
  const [answers, setAnswers] = useState<number[]>([])
  const [userAnswers, setUserAnswers] = useState<number[]>([])

  useEffect(() => {
    setAnswers(selected || [])

    if (userAnswer) {
      const toNumberArray = userAnswer.toString().includes("-")
        ? userAnswer.split("-").map((a) => Number(a))
        : [Number(userAnswer)]
      setUserAnswers(toNumberArray)
    }
  }, [selected, userAnswer])

  const prioritized = (answers || []).map((p) =>
    options.find((o) => o.value === p)
  ).filter(Boolean)
  const notPrioritized = options.filter((o) => !prioritized.includes(o))
  const { t } = useTranslation()

  const prioritizeOption = (toPrioritize: IQuestionsOption) => {
    if (disabled || readonly) return
    setAnswers([...prioritized, toPrioritize].map((item) => item!.value))
    setTimeout(() => focusOnIndicator(), 10)
  }

  const dePrioritizeOption = (toDePrioritize: IQuestionsOption) => {
    if (disabled || readonly) return
    const stillPrioritized = prioritized.filter(
      (item) => item?.value !== toDePrioritize.value
    )
    setAnswers(stillPrioritized.map((item) => item!.value))
    setTimeout(() => focusOnIndicator(), 10)
  }

  const focusOnIndicator = () =>
    document.getElementById(`done-indicator-${questionId}`)?.focus()

  const onAnswerClick = () => {
    if (onAnswer) {
      onAnswer(answers)
    }
  }

  return (
    <PriorityListContainer
      height={height}
      disabled={disabled}
      className={className}
      width={width}
    >
      {title && <Title>{title}</Title>}
      <Explanation>{explanationText || ""}</Explanation>
      {prioritized.length === 0 && (
        <DoneIndicator
          tabIndex={-1}
          id={`done-indicator-${questionId}`}
          bgColor={overriderColors?.infoBg || theme.color.grey.light}
        >
          <FiHelpCircle height={20} color={theme.color.black} />
          <p>{t("questionsPage.prioSelectMostImportant")}</p>
        </DoneIndicator>
      )}
      {prioritized.length >= 1 && prioritized.length !== options.length && (
        <DoneIndicator
          tabIndex={-1}
          id={`done-indicator-${questionId}`}
          bgColor={overriderColors?.infoBg || theme.color.grey.light}
        >
          <FiHelpCircle height={20} color={theme.color.black} />
          <p>
            {t("questionsPage.prioNotDone")}{" "}
            {options.length - prioritized.length}
          </p>
        </DoneIndicator>
      )}
      {prioritized.length === options.length && !readonly && (
        <DoneIndicator
          tabIndex={-1}
          id={`done-indicator-${questionId}`}
          bgColor={overriderColors?.doneBg || theme.color.grey.light}
        >
          <FiCheck height={20} color={theme.color.black} />
          <p>{t("questionsPage.prioDone")}</p>
        </DoneIndicator>
      )}
      <StyledTransitionGroup>
        {prioritized.length > 0 &&
          prioritized.map((p, index) => (
            <CSSTransition
              key={p?.value}
              timeout={{
                enter: 100,
                appear: 100,
                exit: 0,
              }}
              classNames="item"
            >
              <ListItem>
                <PriorityListOption
                  selected
                  onClick={dePrioritizeOption}
                  disabled={disabled}
                  option={p!}
                  positionNumber={index + 1}
                  readonly={readonly}
                  // eslint-disable-next-line no-underscore-dangle
                  optionText={getLocalizedText(p)}
                />
                {showSpeechBubbles && userAnswers.indexOf(p!.value) >= 0 && (
                  <AbsoluteTooltip
                    aria-hidden="true"
                    backgroundColor={theme.color.grey.light}
                    color={theme.color.grey.dark}
                  >
                    <MinWidthTooltipContent>
                      {userAnswers.indexOf(p!.value) + 1}
                    </MinWidthTooltipContent>
                  </AbsoluteTooltip>
                )}
              </ListItem>
            </CSSTransition>
          ))}
      </StyledTransitionGroup>
      {prioritized.length !== options.length && !readonly && <Separator />}

      <TransitionGroup>
        {notPrioritized?.map((np) => (
          <CSSTransition
            key={np.id}
            timeout={{
              enter: 100,
              appear: 100,
              exit: 0,
            }}
            classNames="item"
          >
            <ListItem>
              <PriorityListOption
                onClick={prioritizeOption}
                disabled={disabled}
                option={np}
                optionText={getLocalizedText(np)}
                readonly={readonly}
              />
              {showSpeechBubbles && userAnswers.indexOf(np!.value) >= 0 && (
                <AbsoluteTooltip
                  aria-hidden="true"
                  backgroundColor={theme.color.grey.light}
                  color={theme.color.grey.dark}
                >
                  <MinWidthTooltipContent>
                    {userAnswers.indexOf(np!.value) + 1}
                  </MinWidthTooltipContent>
                </AbsoluteTooltip>
              )}
            </ListItem>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {answers.length === options.length && !readonly && (
        <SaveButton
          data-cy="save-answer"
          key={`save_button_${questionId}`}
          intent="primary"
          onClick={() => onAnswerClick()}
        >
          <CheckMarkAtom />
          {t("questionsPage.save")}
        </SaveButton>
      )}
    </PriorityListContainer>
  )
}

const Separator = styled.div`
  border-bottom: 2px dashed #333;
  margin: 12px -24px;
`

const PriorityListContainer = styled.fieldset<{
  height?: string
  disabled?: boolean
  width: string
}>`
  border: none;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  opacity: ${({ disabled }) => disabled && 0.5};
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  margin-bottom: 10px;
`

const Title = styled.legend``

const Explanation = styled.p`
  display: block;
  text-align: center;
  font-size: ${theme.fontSize.small};
  :focus {
    outline: auto;
  }
`

const DoneIndicator = styled.div<{ bgColor: string }>`
  height: 48px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 16px 0px;
  padding-left: 16px;
  p {
    margin: 12px;
    font-size: ${theme.fontSize.medium};
  }
  &:focus {
    outline: none;
  }
`

const StyledTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 4px;
`

const ListItem = styled.div`
  position: relative;
  width: 100%;
`

const SaveButton = styled(Button)`
  margin: 15px auto 80px;
  transition: all 0.2s;
  ${CheckMarkAtom} {
    width: 26px !important;
    height: 26px;
  }
  && {
    background-color: ${theme.color.success.default};
    display: flex;
    flex-direction: row;
    justify-self: center;
    width: max-content;
  }
`

const AbsoluteTooltip = styled(Tooltip)`
  position: absolute;
  left: 100%;
  top: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`

const MinWidthTooltipContent = styled(TooltipContent)`
  min-width: 4ch;
`

export const PriorityList = styled(PriorityListComponent)``
