import styled from "styled-components"
import { theme } from "../theme"

export const TooltipContent = styled.span`
  align-self: center;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  white-space: nowrap;
  border-radius: 5px;
  padding: 0 8px;
`

export const Tooltip = styled.div<{
  backgroundColor: string
  color: string
}>`

  color: ${({ color }) => color ?? theme.color.grey.light};
  display: grid;
  grid-template-columns: 9px auto;
  justify-self: flex-start;
  align-self: center;
  height: 40px;

  &:before {
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 100% 100% 100% 0;
    margin-left: 5px;
    transform: rotate(45deg);
    background-color: ${({ backgroundColor }) =>
      backgroundColor};
    align-self: center;
  }
  & ${TooltipContent} {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ?? theme.color.grey.dark};
  }
`
