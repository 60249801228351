import { render } from 'react-dom'
import { App } from './app'

const rootElement = document.getElementById('voting-compass-app')
if (rootElement) {
  const electionId = rootElement.getAttribute('data-election-id')
  if (!electionId) {
    throw new Error('No "data-electionid" set in "app" element!')
  }
  const lng = rootElement.getAttribute('data-lng') || 'fi'
  render(
    App(electionId, lng),
    document.getElementById('voting-compass-app')
  )
} else {
  console.error('Could not find root element to attach the Vaalikone app')
}
