/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { theme } from '../theme'

import { InputAtom, IInputAtomProps } from '../atoms/Input.atom'

const InputBase: FunctionComponent<IInputAtomProps> = props => (
  <InputText {...props} />
)

const InputText = styled(InputAtom)`
  display: block;
  border-radius: 2px;
  box-shadow: none;
  font-size: ${theme.fontSize.medium};
  color: ${theme.color.grey.default};
  transition: all 0.2s ease-in;
  padding: 0.75rem 0.75rem;
  background-color: #fff;
  width: 100%;
  
  &:focus {
    color: ${theme.color.black};
    border-color: ${theme.color.grey};
    outline: 0;
  }
  
  &:disabled {
    background-color: #eee;
    color: ${theme.color.grey};
    cursor: not-allowed;
  }
`

export const Input = styled(InputBase)`
  position: relative;
`
