import React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'

export const InfoTooltip: React.FC<{title: string, className?: string}> = ({ title, className }) => (
  <Wrapper className={className}>
    <Img src={`${process.env.IMAGES_PREFIX}/info.svg`} />
    <Popup>{title}</Popup>
  </Wrapper>
)

const Img = styled.img`
  width: 16px;
`

const Popup = styled.div`
  visibility: hidden;
  font-size: 12px;
  background-color: ${theme.color.grey.default};
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  bottom: 100%;
  left: 0;
  transform: translateX(-100%);
  position: absolute;
  z-index: 1;
  min-width: 200px;
  &:hover {
    visibility: visible;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  
  &:hover ${Popup} {
    visibility: visible;
  }
`
