import styled from 'styled-components'
import { theme } from '../theme'

export const HelperText = styled.small`
  color: ${theme.color.grey.darkest};
  font-size: 12px;
  padding: 10px 20px;
  margin: 10px;
  background-color: ${theme.color.grey.lightest};
  display: inline-block;
  box-sizing: border-box;
  border-radius: 4px;
`
