import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CandidateGridCard } from '../elements/CandidateGridCard'
import { Input } from '../elements/Input'
import { raise , theme } from '../theme'
import { useData } from '../stores'
import { BrandColorLine } from '../elements/BrandColorLine'
import { Card } from '../elements/Card'
import { ICandidate } from '../types/data.types'
import { toLanguagePath } from '../utils/I18nHelper'
import { FilterCard } from '../cards/FilterCard'

export const {BUCKET_URL} = process.env

export const CandidatesPage: React.FC = () => {
  const { candidates, groups, alliances, circles } = useData(({ dataStore }) => ({
    candidates: dataStore.candidates,
    groups: dataStore.electionData.groups,
    alliances: dataStore.electionData.alliances,
    circles: dataStore.electionData.circles
  }))

  const { i18n, t } = useTranslation()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCandidates, setFilteredCandidates] = useState(candidates)

  const filterResults = useCallback(() => {
    const search = searchTerm.toLowerCase().trim()
    const groupsInElection = groups.length > 0

    const nameFilter = (a: ICandidate[]) => a.filter((c) => {
      const cAlliance = alliances.find(alliance => alliance.id === c.electoralAllianceId)
      const cCircle = circles.find(ci => ci.id === c.electoralCircleId)
      return c.firstName.toLowerCase().includes(search) ||
        c.lastName.toLowerCase().includes(search) ||
        cAlliance?.name.toLowerCase().includes(search) ||
        cCircle?.name.toLowerCase().includes(search)
    })

    const groupless = filteredCandidates.filter(c => !c.groupId)
    if (search) {
      return {
        groups,
        candidates: nameFilter(filteredCandidates),
        showOthersGroup: groupsInElection && nameFilter(groupless).length > 0,
        noGroupCandidates: nameFilter(groupless)
      }
    }
    return { groups, candidates: sortAlphabetically(filteredCandidates), showOthersGroup: !search,  noGroupCandidates: sortAlphabetically(groupless) }
  }, [searchTerm, filteredCandidates])

  const sortAlphabetically = (candidatesToSort: ICandidate[]) =>
    candidatesToSort.sort((a, b) => `${a.lastName} ${a.firstName} `.localeCompare(`${b.lastName} ${b.firstName}`))

  const groupHasCandidates = (groupId: string) => filterResults().candidates.filter(c => c.groupId === groupId).length > 0

  const electionWithGroups = () => {
    const cands = filterResults().candidates
    return filterResults().groups.sort((a, b) => a.name.localeCompare(b.name)).map(g =>
      groupHasCandidates(g.id) && <CandidatesWrapper key={g.id} title={g.name}>
        <hr />
        <CandidateGrid key={g.id}>
          {cands.map((c) => {
            const candidateGroup = groups.find(group => group.id === c.groupId)
            return g.id === c.groupId && <CandidateGridCard
              key={c.id}
              candidate={c}
              group={candidateGroup}
              onClick={() => history.push(toLanguagePath(`/ehdokkaat/${c.id}`, i18n))}
            />
          })}
        </CandidateGrid>
      </CandidatesWrapper>
    )
  }

  const electionWithoutGroups = () => (
    <CandidateGrid>
      {filterResults().candidates.map(c =>
        <CandidateGridCard
          key={c.id}
          candidate={c}
          onClick={() => history.push(toLanguagePath(`/ehdokkaat/${c.id}`, i18n))}
        />
      )}
    </CandidateGrid>
  )

  const noGroupCandidates = () => {
    if (filterResults().noGroupCandidates && filterResults().noGroupCandidates.length > 0 && filterResults().showOthersGroup) {
      return <CandidatesWrapper title={t('candidatesPage.noGroup')}>
        <hr />
        <CandidateGrid key="groupless">
          {(filterResults().noGroupCandidates || []).map(c =>
            <CandidateGridCard
              key={c.id}
              candidate={c}
              onClick={() => history.push(toLanguagePath(`/ehdokkaat/${c.id}`, i18n))}
            />
          )}
        </CandidateGrid>
      </CandidatesWrapper>
      }
      return undefined
  }

  const onlyNoGroupCands = candidates.filter(c => !c.groupId).length === candidates.length

  return (
    <>
      <BrandColorLine />
      <PageContainer>
        <InputWrapper>
          <SearchInput
            data-cy="candidate-search"
            placeHolder={t('candidatesPage.search')}
            type="text"
            onChange={e => setSearchTerm(e)}
          />
          <FilterCard
            filteredCandidatesCallback={candidatesFiltered => setFilteredCandidates(candidatesFiltered)}
            candidates={candidates}
          />
        </InputWrapper>
        {groups.length > 0 && !onlyNoGroupCands ? electionWithGroups() : electionWithoutGroups()}
        {groups.length > 0 &&  noGroupCandidates()}
      </PageContainer>
    </>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 8px 20px rgba(218, 224, 235, 0.6);
  padding: 20px;
  width: 100%;

  ${Card} {
    h1 {
      color: ${theme.color.grey.dark};
    }
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;

  ${FilterCard} {
    margin-top: 15px;
  }
`

const SearchInput = styled(Input)`
  border: none;
  border-radius: 100vh;
  padding: 16px;
  width: 100%;
  ${raise(1)};
`

const CandidateGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
`

const CandidatesWrapper = styled(Card)`
  box-shadow: none;
  h1 {
    word-break: break-word;
    color: ${theme.color.grey.dark};
    margin: 0;
  }
  hr {
    color: ${theme.color.grey.lightest};
    margin-top: 0;
    margin-bottom: 20px;
  }
`
