import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CircleAnswerButton } from "./AnswerButton"
import { theme } from '../theme'
import { IQuestion } from '../types/data.types'
import { device } from '../theme/deviceSize'
import { useId } from '../utils/hooks'

interface IProps {
  className?: string
  question: IQuestion
  value?: number
  answerToQuestion?: (questionId: string, value: number) => void
  userAnswer?: number
}
const AnswersOneToFiveElement: React.FC<IProps> = ({ question, value, answerToQuestion, className, userAnswer }) => {
  const { t } = useTranslation()
  const answer = (val: number) => answerToQuestion && answerToQuestion(question.id, val)
  const readOnly = !answerToQuestion
  const id = useId()
  return (
    <div className={className}>
      <CircleAnswerButton
        data-cy="answer-1"
        key="1"
        readOnly={readOnly}
        name={id}
        value={1}
        checked={value === 1}
        onChange={() => answer(1)}
        label={t('questionsPage.disagree')}
        userAnswered={userAnswer === 1}
      />
      <CircleAnswerButton
        data-cy="answer-2"
        key="2"
        readOnly={readOnly}
        name={id}
        value={2}
        checked={value === 2}
        onChange={() => answer(2)}
        userAnswered={userAnswer === 2}
        label={t('questionsPage.mildlyDisagree')}
      />
      <CircleAnswerButton
        data-cy="answer-3"
        key="3"
        readOnly={readOnly}
        name={id}
        value={3}
        checked={value === 3}
        onChange={() => answer(3)}
        label={t('questionsPage.dontKnow')}
        userAnswered={userAnswer === 3}
      />
      <CircleAnswerButton
        data-cy="answer-4"
        key="4"
        readOnly={readOnly}
        name={id}
        value={4}
        checked={value === 4}
        onChange={() => answer(4)}
        userAnswered={userAnswer === 4}
        label={t('questionsPage.mildlyAgree')}
        
      />
      <CircleAnswerButton
        data-cy="answer-5"
        key="5"
        readOnly={readOnly}
        name={id}
        value={5}
        checked={value === 5}
        onChange={() => answer(5)}
        label={t('questionsPage.agree')}
        userAnswered={userAnswer === 5}
      />
    </div>
  )
}

export const AnswerOneToFive = styled(AnswersOneToFiveElement)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 480px;
  position: relative;
  margin: 0 auto 80px;
  
  ${ CircleAnswerButton} {
    width: 20%;
  }
  &:before {
    content: ' ';
    height: ${({ answerToQuestion }) => !answerToQuestion ? '2px' : '4px'};
    display: block;
    top: ${({ answerToQuestion }) => !answerToQuestion ? '19px' : '28px'};
    left: 20px;
    right: 20px;
    position: absolute;
    background: ${theme.color.grey.light};

    @media only screen and ${device.mobileL} {
      height: 2px;
      top:19px;
    }
    @media only screen and ${device.mobileS} {
      height: 2px;
      top:14px;
    }
  }
`
