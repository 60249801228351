import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FiChevronDown, FiSearch } from 'react-icons/fi'
import { raise, theme } from '../theme'
import { useClickOutside } from '../utils/hooks'
import { DropdownOption } from '../atoms/DropDownOption.atom'

export interface IDropdownOption {
  key: string | number
  title: React.ReactNode
}
interface IProps {
  className?: string
  options: IDropdownOption[]
  selected?: string | number
  placeholder: React.ReactNode
  // eslint-disable-next-line react/no-unused-prop-types
  wide?: boolean
  color?: string
  disabled?: boolean
  onSelect: (value: string | number) => void
  withSearch?: boolean
  'data-cy'?: string
}

const DropDownBase: React.FunctionComponent<IProps> = (props) => {
  const { className, color, options, disabled, withSearch, selected, placeholder, onSelect } = props
  const ref = useRef(null)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>(String(selected) || '')
  const {t} = useTranslation()

  useClickOutside(ref, () => closeMenu(), menuOpen)

  const closeMenu = () => setMenuOpen(false)

  const toggleMenu = () => !disabled && setMenuOpen(oldMenuOpen => !oldMenuOpen)

  const isSelected = (option: IDropdownOption) => option.key === selected

  const getButtonText = () => {
    if (selected) {
      const found = options.find(o => o.key === selected)
      return found ? found.title : placeholder
    }
    return placeholder
  }

  const selectOption = (option: IDropdownOption) => (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (option.key !== selected) {
      onSelect(option.key)
      closeMenu()
    }
  }

  const areaOptions = () => withSearch ? options.filter(o => String(o.title!).toLowerCase().includes(searchString.toLowerCase())) : options

  return (
    <div className={className} ref={ref}>
      {withSearch ?
        <SearchWrapper>
          <SearchInput placeholder={t('homePage.area')} onClick={() => {
            setSearchString('')
            setMenuOpen(!menuOpen)
          }}
          value={!menuOpen ? String(getButtonText()) : searchString} onChange={e => setSearchString(e.target.value)} />
          <FiSearch size={18}/>
        </SearchWrapper>
         :
        <DropdownButton data-cy={props['data-cy']} color={color} onClick={toggleMenu} disabled={disabled}>
          {getButtonText()} <FiChevronDown size={18}/>
        </DropdownButton>
      }

      <DropdownMenu>
      { menuOpen && areaOptions().map((o, i) =>
        <DropdownOption data-cy={o.key} isSelected={isSelected(o)} key={o.key} onClick={selectOption(o)}>
          {o.title}
        </DropdownOption>
      )}
      </DropdownMenu>
    </div>
  )
}

export const Dropdown = styled(DropDownBase)<IProps>`
  width: ${ props => props.wide && '100%' };
`

const ButtonStyles = `
  width: 240px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  font-size: ${theme.fontSize.medium};
  font-family: ${theme.font.text};
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.grey.dark};
  cursor: pointer;
`

const DropdownButton = styled.button`
  ${ButtonStyles}
`

const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    position: absolute;
    margin-right: 10px;
  }
`

const SearchInput = styled.input`
  ${ButtonStyles}
  padding: 10px 30px 10px 10px;
`

const DropdownMenu = styled.div`
  background-color: ${theme.color.white};
  position: absolute;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:240px;
  max-width: 100%;
  z-index: 99;
  max-height: 300px;
  overflow-y: auto;
  ${raise(3)};
  max-height: 180px;
`
