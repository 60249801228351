/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import { Size } from '../theme'

interface IProps {
  className?: string
  onClick?: () => void
  disabled?: boolean
  size?: Size
  loading?: boolean
  intent?: string
  color?: string
  bgColor?: string
  fill?: string
  value?: number
  name?: string
  children?: React.ReactNode
  'data-cy'?: string
}

export const ButtonAtom: React.FunctionComponent<IProps> = props => (
  <button
    data-cy={props['data-cy']}
    className={props.className}
    type={props.onClick ? 'button' : 'submit'}
    onClick={props.onClick && props.onClick}
    value={props.value}
    name={props.name}
    disabled={props.disabled}>
    {props.children}
  </button>
)
