import React, { useState } from 'react'
import styled from 'styled-components'
import { FiChevronDown } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { Card } from '../elements/Card'
import { theme } from '../theme'
import { Pill } from '../elements/Pill'
import { useData } from '../stores'
import { IGroup, IArea, ICandidate, IAlliance, ICircle } from '../types/data.types'
import { BrandColorLine } from '../elements/BrandColorLine'
import { Button } from '../elements/Button'

export interface IResultCandidate extends ICandidate {
  candidateId?: string
  score?: number
  categoryScores?: { [key: string]: number }
}

interface IProps {
  className?: string
  candidates: IResultCandidate[]
  filteredCandidatesCallback: (filteredCandidates: IResultCandidate[]) => void
}

export type CandidateFilterType =
  | 'BELOW_THIRTY'
  | 'THIRTY_TO_FIFTY'
  | 'ABOVE_FIFTY'
  | 'FEMALE'
  | 'MALE'
  | 'OTHER'
  | 'GROUP'
  | 'AREA'
  | 'ALLIANCE'
  | 'CIRCLE'

const FilterCardElement: React.FC<IProps> = ({ className, candidates, filteredCandidatesCallback }) => {
  const { groups, areas, bgQuestions, areaOptions, circles, alliances } = useData(({ dataStore, resultStore }) => ({
    groups: dataStore.electionData.groups,
    areas: dataStore.areas,
    areaOptions: resultStore.areaOptions,
    bgQuestions: dataStore.electionData.bgQuestions,
    alliances: dataStore.electionData.alliances.sort((a, b) => a.name.localeCompare(b.name)),
    circles: dataStore.electionData.circles.sort((a, b) => a.name.localeCompare(b.name))
  }))

  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState<string[]>([])
  const { t } = useTranslation()

  const deselectFilters = () => {
    setFilters([])
    filteredCandidatesCallback(candidates)
  }

  // eslint-disable-next-line consistent-return
  const setFilter = (filter: CandidateFilterType, group?: IGroup, area?: IArea, alliance?: IAlliance, circle?: ICircle) => {
    const tempFilters = [...filters]
    let filterId = filter
    if (group) {
      filterId = `${filterId}${`_${group.id.split('GROUP_')[1]}`}` as CandidateFilterType
    }
    if (alliance) {
      filterId = `${filterId}${`_${alliance.id.split('ALLIANCE_')[1]}`}` as CandidateFilterType
    }
    if (circle) {
      filterId = `${filterId}${`_${circle.id.split('CIRCLE_')[1]}`}` as CandidateFilterType
    }
    if (area) {
      filterId = `${filterId}${`_${area.id.split('AREA_')[1]}`}` as CandidateFilterType
    }

    if (tempFilters.includes(filterId)) {
      tempFilters.splice(tempFilters.indexOf(filterId), 1)
    } else {
      tempFilters.push(filterId)
    }
    setFilters(tempFilters)

    if (tempFilters.length === 0) {
      return filteredCandidatesCallback(candidates)
    }

    const filteredResults = candidates.filter((candidate) => {
      let isValidAge
      let isValidSex
      let isValidGroup
      let isValidArea
      let isValidAlliance
      let isValidCircle

      if (candidate) {
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const index in tempFilters) {
          if (!isValidGroup) {
            if (tempFilters[index].includes('GROUP')) {
              const filterGroup = tempFilters[index]
              isValidGroup = candidate.groupId ? candidate.groupId === filterGroup : false
            }
          }
          if (!isValidArea) {
            if (tempFilters[index].includes('AREA')) {
              const filterArea = tempFilters[index]
              isValidArea = candidate.areaId ? candidate.areaId === filterArea : false
            }
          }
          if (!isValidAlliance) {
            if (tempFilters[index].includes('ALLIANCE')) {
              const filterAlliance = tempFilters[index]
              isValidAlliance = candidate.electoralAllianceId ? candidate.electoralAllianceId === filterAlliance : false
            }
          }
          if (!isValidCircle) {
            if (tempFilters[index].includes('CIRCLE')) {
              const filterCircle = tempFilters[index]
              isValidCircle = candidate.electoralCircleId ? candidate.electoralCircleId === filterCircle : false
            }
          }
          if (!isValidAge) {
            switch (tempFilters[index]) {
              case 'BELOW_THIRTY':
                isValidAge = candidate.age ? candidate.age < 30 : false
                break
              case 'THIRTY_TO_FIFTY':
                isValidAge = (candidate.age && candidate.age >= 30 && candidate.age <= 50) || false
                break
              case 'ABOVE_FIFTY':
                isValidAge = (candidate.age && candidate.age > 50) || false
                break
              default:
                break
            }
          }
          if (!isValidSex) {
            switch (tempFilters[index]) {
              case 'FEMALE':
                isValidSex = candidate.sex === 'FEMALE' || false
                break
              case 'MALE':
                isValidSex = candidate.sex === 'MALE' || false
                break
              case 'OTHER':
                isValidSex = candidate.sex === 'OTHER' || false
                break
              default:
                break
            }
          }
        }
      }

      const values = [isValidAge, isValidSex, isValidGroup, isValidArea, isValidAlliance, isValidCircle].filter(val => val !== undefined)
      if (values.length > 0 && values.every(val => val === true)) {
        return true
      }
      return false
    })
    filteredCandidatesCallback(filteredResults)
  }

  const sexFilters = {
    FEMALE: t('resultsPage.female'),
    MALE: t('resultsPage.male'),
    OTHER: t('resultsPage.other')
  }

  const ageFilters = {
    BELOW_THIRTY: t('resultsPage.belowThirty'),
    THIRTY_TO_FIFTY: t('resultsPage.thirtyToFifty'),
    ABOVE_FIFTY: t('resultsPage.aboveFifty')
  }

  const sexUsedInElections = bgQuestions && bgQuestions.find(bgQ => bgQ.type === 'SEX')
  const ageUsedInElections = bgQuestions && bgQuestions.find(bgQ => bgQ.type === 'AGE')

  const showAreaFilter = !areaOptions.presetAreaId && !areaOptions.showAreaDropdown
  return (
    <div className={className}>
      <BrandColorLine />
      <FilterCardContainer isOpen={isOpen}>
        <ResultHeading data-cy="open-filters" onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
          {t('resultsPage.filterCandidates')} <FiChevronDown />
        </ResultHeading>
        <div>
          {ageUsedInElections && <FilterTypeName>{t('resultsPage.age')}</FilterTypeName>}
          <PillsContainer>
            {ageUsedInElections &&
              Object.keys(ageFilters).map(af => (
                <Pill
                  data-cy={af}
                  key={af}
                  isSelected={filters.includes(af)}
                  onClick={() => setFilter(af as CandidateFilterType)}
                  text={ageFilters[af]}
                />
              ))}
          </PillsContainer>
        </div>
        <div>
          {sexUsedInElections && <FilterTypeName>{t('resultsPage.sex')}</FilterTypeName>}
          <PillsContainer>
            {sexUsedInElections &&
              Object.keys(sexFilters).map(sf => (
                <Pill
                  key={sf}
                  data-cy={sf}
                  isSelected={filters.includes(sf)}
                  onClick={() => setFilter(sf as CandidateFilterType)}
                  text={sexFilters[sf]}
                />
              ))}
          </PillsContainer>
        </div>
        {groups && groups.length > 0 && (
          <div>
            <FilterTypeName>{t('resultsPage.group')}</FilterTypeName>
            <PillsContainer>
              {groups.map(group => (
                <Pill
                  key={group.id}
                  data-cy={group.abbreviation}
                  color={group.color}
                  isSelected={filters.includes(group.id)}
                  onClick={() => setFilter('GROUP', group)}
                  text={group.abbreviation}
                  hoverText={group.name}
                />
              ))}
            </PillsContainer>
          </div>
        )}
        {showAreaFilter && areas && areas.length > 0 && (
          <div>
            <FilterTypeName>{t('resultsPage.area')}</FilterTypeName>
            <PillsContainer>
              {areas.map(area => (
                <Pill
                  key={area.id}
                  isSelected={filters.includes(area.id)}
                  onClick={() => setFilter('AREA', undefined, area)}
                  text={area.name}
                  hoverText={area.name}
                />
              ))}
            </PillsContainer>
          </div>
        )}
        {circles && circles.length > 0 && (
          <div>
            <FilterTypeName>{t('resultsPage.circle')}</FilterTypeName>
            <PillsContainer>
              {circles.map(circle => (
                <Pill
                  key={circle.id}
                  data-cy={circle.abbreviation}
                  isSelected={filters.includes(circle.id)}
                  onClick={() => setFilter('CIRCLE', undefined, undefined, undefined, circle)}
                  text={circle.name}
                  hoverText={circle.name}
                />
              ))}
            </PillsContainer>
          </div>
        )}
        {alliances && alliances.length > 0 && (
          <div>
            <FilterTypeName>{t('resultsPage.alliance')}</FilterTypeName>
            <PillsContainer>
              {alliances.map(alliance => (
                <Pill
                  key={alliance.id}
                  data-cy={alliance.abbreviation}
                  isSelected={filters.includes(alliance.id)}
                  onClick={() => setFilter('ALLIANCE', undefined, undefined, alliance)}
                  text={alliance.name}
                  hoverText={alliance.name}
                />
              ))}
            </PillsContainer>
          </div>
        )}
        <ClearButtonWrapper>
          <Button data-cy="clear-filters" onClick={() => deselectFilters()} intent="secondary">
            {t('resultsPage.clearFilters')}
          </Button>
        </ClearButtonWrapper>
      </FilterCardContainer>
    </div>
  )
}

const ResultHeading = styled.p<{ isOpen: boolean }>`
  color: ${theme.color.grey.default};
  margin: 0;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.25s ease-out;
    margin: 2px 0 0 10px;
  }
`

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const FilterTypeName = styled.p`
  margin: 16px 0 !important;
`

const FilterCardContainer = styled(Card)<{ isOpen: boolean }>`
  margin-bottom: 10px;
  max-height: ${({ isOpen }) => (isOpen ? '10000px' : '64px')};
  height: 100%;
  transition: max-height 0.4s ease;
  overflow: hidden;
  min-height: 64px;
`

const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const FilterCard = styled(FilterCardElement)``
