/* eslint-disable react/no-unused-prop-types,@typescript-eslint/no-unused-expressions */
import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircleButtonElement } from './CircleButton'
import { ButtonAtom } from '../atoms/Button.atom'
import { theme, raise, Intent, getBackgroundColor } from '../theme'
import { hexColorDark } from '../utils/Color.util'
import { useId } from '../utils/hooks'

interface IButtonProps {
  className?: string
  name: string
  value: number
  label?: string
  readOnly?: boolean
  userAnswered?: boolean
  'data-cy'?: string
}

interface ICircleButtonProps extends IButtonProps {
  onChange: () => void
  checked?: boolean
}

interface IRectangularButtonProps extends IButtonProps {
  onClick: () => void
  intent: Intent
}

const CircleAnswerButtonElement: React.FC<ICircleButtonProps> =
  ({ className, readOnly, name, value, checked, onChange, label, userAnswered, ...props }) => {
    const { t } = useTranslation()
    const id = useId()
    
    return (
      <div className={className}>
        {userAnswered &&
          <>
            <UserAnswer>{t('candidatePage.you')}</UserAnswer>
            <ArrowDown />
          </>
        }
        <CircleButtonElement data-cy={props['data-cy']} readOnly={!!readOnly} name={name}
          value={value} checked={checked} onClick={() => { checked && onChange() }} onChange={onChange} id={id} aria-label={label} />
        <label htmlFor={id}>{label || ' '}</label>
      </div>
    )
  }

export const RectangularAnswerButton: React.FC<IRectangularButtonProps> =
  ({ className, value, onClick, intent, readOnly, userAnswered }) => {
    const { t } = useTranslation()
    return (
      <RectangularAnswerButtonElement
        className={className}
        intent={intent}
        onClick={onClick}
        disabled={!!readOnly} >
        {userAnswered && <>
                <UserAnswer>{t('candidatePage.you')}</UserAnswer>
                <ArrowDown />
              </>}
        {value}
      </RectangularAnswerButtonElement>
    )
  }

export const YesNoButton: React.FC<IRectangularButtonProps> =
  ({ className, name, value, onClick, label, intent, readOnly, userAnswered, ...props }) => {
    const { t } = useTranslation()
    return (
      <YesNoButtonContainer className={className}>
        {userAnswered &&
          <>
            <UserAnswer>{t('candidatePage.you')}</UserAnswer>
            <ArrowDown />
          </>
        }
        <YesNoButtonElement data-cy={props['data-cy']} name={name} value={value} onClick={onClick} intent={intent} disabled={readOnly}>
          <span>{label || ' '}</span>
        </YesNoButtonElement>
      </YesNoButtonContainer>
    )
  }

export const CircleAnswerButton = styled(CircleAnswerButtonElement)<{disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  align-items: center;
  justify-content: center;

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &.check {
      stroke-dashoffset: -100;
      animation: dash-check .6s .1s ease-in-out forwards;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  p, label {
    text-align: center;
    font-size: ${theme.fontSize.xs};
    font-weight: 600;
    color: ${theme.color.grey.default};
  }
`

export const UserAnswer = styled.div`
  position: absolute;
  top: -38px;
  padding: 5px 8px;
  background-color: ${theme.color.grey.light};
  border-radius: 3px;
  font-size: ${theme.fontSize.small};
  font-family: ${theme.font.text};
  font-weight: 600;
  color: ${theme.color.grey.dark};
  ${raise(2)}
`

export const ArrowDown = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: -16px;
  background-color: ${theme.color.grey.light};
  transform: rotate(45deg);
`

const RectangularAnswerButtonElement = styled(ButtonAtom) <{ intent: Intent, disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  width: 36px;
  min-height: 36px;
  background-color: ${({ intent }) => getBackgroundColor(intent)};   
  text-align: center;
  font-size: ${theme.fontSize.small};
  font-weight: 600;
  color: ${({ intent }) => hexColorDark(getBackgroundColor(intent)) ? theme.color.grey.lightest : theme.color.grey.default};
  &:hover {
    ${raise(3)};
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }
`

const YesNoButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  ${UserAnswer} {
    position: relative;
    top: 0;
    margin: 5px 0;
    z-index: 1;
  }
  ${ArrowDown} {
    top: 29px;
  }
`

const YesNoButtonElement = styled(ButtonAtom) <({ intent: Intent, disabled?: boolean }) >`
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  width: 200px;
  min-height: 36px;
  background-color: ${({ intent }) => getBackgroundColor(intent)};
  align-items: center;
  transition: all 0.2s;
  border-radius: 6px;
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);

  &:hover {
    ${raise(3)};
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  }

  &:active {
    opacity: 0.9;
  }

  span {
    text-align: center;
    font-size: ${theme.fontSize.small};
    font-weight: 600;
    color: ${({ intent }) => hexColorDark(getBackgroundColor(intent)) ? theme.color.grey.lightest : theme.color.grey.dark};
  }
`
