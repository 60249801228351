import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../theme'

import { GroupTag } from '../atoms/GroupTag.atom'

import { IBgQuestion, IDetailedCandidate, IGroup, LinkType, IArea, IAlliance, ICircle } from '../types/data.types'
import { Card } from '../elements/Card'
import { RoundImage } from '../elements/Image'
import { parseText } from '../utils/Text.util'
import { device } from '../theme/deviceSize'

interface IProps {
  className?: string
  candidate: IDetailedCandidate
  bgQuestions?: IBgQuestion[]
  group?: IGroup
  area?: IArea
  alliance?: IAlliance
  circle?: ICircle
}

const linkTypeIcon = (type: LinkType) =>
  (type === 'FACEBOOK' && 'facebook.svg') ||
  (type === 'INSTAGRAM' && 'instagram.svg') ||
  (type === 'TWITTER' && 'twitter.svg') ||
  (type === 'LINKEDIN' && 'linkedin.svg') ||
  (type === 'YOUTUBE' && 'youtube.svg') ||
  (type === 'TIKTOK' && 'tiktok.svg') ||
  (type === 'WEBSITE' && 'website.svg') ||
  '-'

const hasSocialMedia = (bgQuestions: IBgQuestion[], bgAnswers: { [id: string]: { value: string } }) => {
  if (bgQuestions.length > 0) {
    const links = bgQuestions.filter(q => q.linkType)
    if (links.length === 0) return false

    const linkAnswers = links.filter((link) => bgAnswers[link.id] !== undefined && bgAnswers[link.id].value)

    return linkAnswers.length > 0
  }
  return false
}

const CandidateDetailCardElement: React.FC<IProps> = ({ candidate, className, group, area, bgQuestions = [], circle, alliance }) => {
  const { t, i18n } = useTranslation()
  const textLang = i18n.language === 'fi' ? 'text' : `text_${i18n.language}`
  return (
    <Card className={className}>
      {group && <GroupTag title={group.abbreviation} color={group.color} size="s" />}
      <TopResult>
        <Image
          image={candidate.image}
          placeholder={`${process.env.IMAGES_PREFIX}/man.png`}
          alt={`Ehdokas ${candidate.firstName} ${candidate.lastName}`}
        />
      </TopResult>
      <Name>
        {candidate.firstName} {candidate.lastName}
      </Name>
      <BGInfoContainer>
        {candidate.age && (
          <Question>
            <Label>{t('candidatePage.age')}</Label>
            <Answer>{candidate.age}</Answer>
          </Question>
        )}
        {candidate.sex && (
          <Question>
            <Label>{t('candidatePage.sex')}</Label>
            <Answer>{candidate.sex === 'NO_ANSWER' ? '-' : t(`resultsPage.${candidate.sex.toLowerCase()}`)}</Answer>
          </Question>
        )}
        {candidate.areaId && area && (
          <Question>
            <Label>{t('candidatePage.area')}</Label>
            <Answer>{area.name}</Answer>
          </Question>
        )}
        {bgQuestions.map((bg) => {
          const answer = candidate.bgAnswers[bg.id]
          if (!bg[textLang]) {
            return ''
          }
          return (
            answer ? (
              <Question key={bg.id}>
                <Label dangerouslySetInnerHTML={{ __html: parseText(bg[textLang]) }} />
                <Answer>{answer.value}</Answer>
              </Question>
            ) : undefined
          )
        })}
      </BGInfoContainer>
      <BGInfoContainer>
        {candidate.electionNumber && (
          <Question>
            <Label>{t('candidatePage.candidateNumber')}</Label>
            <Answer>{candidate.electionNumber}</Answer>
          </Question>
        )}
        {circle && (
          <Question>
            <Label>{t('candidatePage.circleHeader')}</Label>
            <Answer>{circle.name}</Answer>
          </Question>
        )}
        {alliance && (
          <Question>
            <Label>{t('candidatePage.allianceHeader')}</Label>
            <Answer>{alliance.name}</Answer>
          </Question>
        )}
        {hasSocialMedia(bgQuestions, candidate.bgAnswers) && (
          <Question>
            <Label>{t('candidatePage.socialMedia')}</Label>
            <SocialMediaWrapper>
              {bgQuestions.map((bg) => {
                const answer = candidate.bgAnswers[bg.id]
                return bg.linkType && answer && answer.value ? (
                  <SocialMediaLink key={bg.id}>
                    <a target="_blank" href={answer.value} rel="noreferrer">
                      <img alt={bg.linkType} src={`${process.env.IMAGES_PREFIX}/${linkTypeIcon(bg.linkType)}`} />
                    </a>
                  </SocialMediaLink>
                ) : (
                  ''
                )
              })}
            </SocialMediaWrapper>
          </Question>
        )}
      </BGInfoContainer>
    </Card>
  )
}
const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 10px;

  @media only screen and (max-width: ${theme.breakPoints.md}) {
    padding: 0;
  }
`
const Label = styled.label`
  font-size: ${theme.fontSize.small};
  font-weight: 600;
  color: ${theme.color.grey.default};
  a {
    text-decoration: underline !important;
    color: ${theme.color.success.default} !important;
  }
`

const Answer = styled.div`
  padding-top: 6px;
  font-size: ${theme.fontSize.medium};
  color: ${theme.color.black};
  display: flex;
  white-space: pre-wrap;
  @media only screen and ${device.tablet} {
    font-size: ${theme.fontSize.small};
    word-break: break-word;
    line-height: 17px;
  }
`

const SocialMediaWrapper = styled(Answer)`
  @media only screen and ${device.mobileL} {
    flex-wrap: wrap;
  }
`

const BGInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media only screen and (max-width: ${theme.breakPoints.md}) {
    margin: 0;
  }
`

const Image = styled(RoundImage)`
  margin-bottom: 10px;
  display: flex;
  align-self: center;
  width: 140px;
  height: 140px;
  border: 1px solid ${theme.color.grey.default};
`

const Name = styled.h3`
  color: ${theme.color.black};
  font-weight: 600;
  font-size: ${theme.fontSize.large};
  text-align: center;
`

const TopResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const CandidateDetailCard = styled(CandidateDetailCardElement)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 80px;

  ${TopResult} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${GroupTag} {
    position: absolute;
    top: 10px;
    left: 10px;
    @media only screen and ${device.mobileM} {
      height: 130px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      font-size: ${theme.fontSize.medium};
      border-radius: 0 0 10px 10px;
      justify-content: center;
      padding: 0;
      position: relative;
      top: -80px;
    }
  }
`

const SocialMediaLink = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;

  img {
    width: 24px;
    height: 24px;
  }

  @media only screen and ${device.tablet} {
    padding: 8px 16px 0 0;
    img {
      width: 32px;
      height: 32px;
    }
  }
`
