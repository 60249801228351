/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { theme, getFontSize, getBackgroundColor, getColor, raise, Intent } from '../theme'
import { ButtonAtom } from '../atoms/Button.atom'
import { hexColorDark } from '../utils/Color.util'
import { IBrandColor } from '../types/data.types'

export const Button = styled(ButtonAtom)`
  position: relative;
  color: ${({ intent, bgColor }) => bgColor ? (hexColorDark(bgColor) ? theme.color.white : theme.color.grey) : getColor(intent! as Intent)};
  font-size: ${props => getFontSize(props.size!)}px;
  font-family: ${theme.font.text};
  font-weight: 600;
  text-align: center;
  background: ${({ intent, bgColor }) => (bgColor || getBackgroundColor(intent! as Intent))};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
  border-radius: 8px;
  border: none;
  outline: none;

  border: 0;
  box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);

  &:active {
    opacity: 0.8;
  }

  padding: 12px 10px;
  width: auto;
  height: auto;
  min-height: 40px;
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    opacity: 1;
    z-index: -1;
  }

  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  }

  &:focus {
      box-shadow: ${raise(1)};
      transform: scale(1.1); 
      outline: 0;
      transition: transform 260ms ease-out;
   }
`

export const ButtonLink = styled(NavLink) <{ brandcolors?: IBrandColor; disabled?: boolean }>`
  &&& {
      position: relative;
      color: ${({ brandcolors }) => brandcolors && brandcolors.textColor};
      background-color: ${({ brandcolors }) => brandcolors && brandcolors.mainColor};
      font-size: ${theme.fontSize.medium};
      font-family: ${theme.font.text};
      font-weight: 600;
      text-align: center;
      border-radius: 8px;
      border: none;
      outline: none;
      border: 0;
      box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
  }
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  cursot: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  padding: 12px 10px;
  width: auto;
  height: auto;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    opacity: 0.8;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  }

  &:focus {
      box-shadow: ${raise(1)};
      transform: scale(1.1); 
      text-decoration: none;
      transition: transform 260ms ease-out;
   }
`
