import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RectangularAnswerButton } from "./AnswerButton"
import { IQuestion, IQuestionType } from '../types/data.types'
import { theme } from '../theme'

interface IProps {
  question: IQuestion
  answerToQuestion?: (questionId: string, value: number) => void
  value?: number
  userAnswer?: number
}

export const AnswersOneToTen: React.FC<IProps> = ({ answerToQuestion, question, value, userAnswer }) => {
  const { t } = useTranslation()
  const getAnswerIntent = (option: number) => (
    value === option
      ? 'primary'
      : 'secondary'
  )

  const setSelection = (questionId: string, val: number) => {
    if (answerToQuestion) answerToQuestion(questionId, val)
  }
  const readOnly = !answerToQuestion
  

  return (<ButtonContainer><RowWrapper questionType={question.type}>
    {[...Array(10).keys()].map(option =>
      <RectangularAnswerButton
        key={`answer-${option + 1}`}
        intent={getAnswerIntent(option + 1)}
        onClick={() => setSelection(question.id, option + 1)}
        name="oneToTenChoice"
        value={option + 1}
        readOnly={readOnly}
        userAnswered={userAnswer === option + 1}
      >
        {option}
      </RectangularAnswerButton >)}
      <TextWrapper>
        <span>{t("questionsPage.oneToTenDisagree")}</span>
        <span>{t("questionsPage.oneToTenAgree")}</span>
      </TextWrapper>
  </RowWrapper>
  </ButtonContainer>
)}

const RowWrapper = styled.div<{ questionType: IQuestionType }>`
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto 80px;
  transition: all 0.2s;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 556px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  > span {
    text-align: center;
    font-size: ${theme.fontSize.xs};
    font-weight: 600;
    color: ${theme.color.grey.default};
  }
`
